import { createAsyncThunk, createSlice,  } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axios-client";

export const fetchWorks = createAsyncThunk(
    'fetchWork',
    async () => {
        let args = {
            url: "/work-json/get-work-list",
            method: "get",
        };
        return axiosClient(args)
    }
)
export const fetchWorkBySlug = createAsyncThunk(
    'fetchWorkBySlug',
    async (slug) => {
        let args = {
            url: `/work-json/get-work-detail/${slug}`,
            method: 'get'
        }
        return axiosClient(args)
    }
)

export const fetchWorkByCategory = createAsyncThunk(
    'fetchWorkByCategory',
    async (category) => {
        let args = {
            url: '/work-json/get-category-works',
            method: "post",
            data: {selectedCategory:category}
        };
        return axiosClient(args)
    }
)

const workSlice = createSlice({
    name: "Work",
    initialState: {
        loading: false,
        works: [],
        workError: null
    },
    extraReducers: (builder) => {
        builder

            //GET ALL Work
            .addCase(fetchWorks.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchWorks.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload?.data?.status === 200) {
                    state.works = action.payload.data.data;
                    state.workError = null
                } else if (action.payload?.data?.error) {
                    state.workError = action.payload.data.message;
                    console.log("Error", action.payload.data);
                }
            })
            .addCase(fetchWorks.rejected, (state, action) => {
                state.loading = false;
                state.workError = `Fetching work Error ${action.error ? action.error.message : "Unknown Error"}`;
            })

        //ADD Work
        // .addCase(addEmployee.pending, (state) => {
        //     state.loading = true;
        // })
        // .addCase(addEmployee.fulfilled, (state, action) => {
        //     state.loading = false;
        //     if (action.payload.status == 200) {
        //         state.employees.push(action.payload.data)
        //     }
        //     state.employeeError = null
        // })
        // .addCase(addEmployee.rejected, (state, action) => {
        //     state.loading = false;
        //     state.employeeError = "Add Employee Error"
        //     if (action.payload.status == 208) {
        //         state.employeeError = "Employee name or email already exist"
        //     } else {
        //         state.employeeError = action.error.message;
        //     }
        // })
    }
})

export default workSlice.reducer;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axios-client";

export const fetchBlogs = createAsyncThunk(
    'fetchBlogs',
    async () => {
        let args = {
            url: "/blogs/verified-blogs-list",
            method: "get",
        };
        return axiosClient(args)
    }
)
export const fetchBlogsByCategory = createAsyncThunk(
    'fetchBlogsByCategory',
    async (category) => {
        let args = {
            url: `/blogs/blogs-category/${category}`,
            method: "get",
        };
        return axiosClient(args)
    }
)
export const fetchBlogCategories = createAsyncThunk(
    'fetch-blogs',
    async () => {
        let args = {
            url: "/blogs/get-blog-categories/",
            method: 'get',
        }
        return axiosClient(args)
    }
)
export const fetchFeaturedBlogs = createAsyncThunk(
    'fetch-featured-blogs',
    async () => {
        let args = {
            url: "/blogs/get-featured-blogs/",
            method: 'get',
        }
        return axiosClient(args)
    }
)
export const fetchBlogBySlug = createAsyncThunk(
    'fetchBlogBySlug',
    async (slug) => {
        let args = {
            url: `/blogs/blogs-detail/${slug}`,
            method: "get",
        };
        return axiosClient(args)
    }
)
export const searchBlog = createAsyncThunk(
    'searchBlog',
    async (data) => {
        let args = {
            url: `/blogs/search-blog`,
            method: "get",
            params: data
        };
        return axiosClient(args)
    }
)
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AppRoutes from './routes/routers'
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import 'react-bootstrap/dist/react-bootstrap.min'

import './assets/style/navigation-bar.css'
import './assets/style/section-hero.css'
import './assets/style/main.css'
import './assets/style/all.min.css'

import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import './assets/js/main'
function App() {
  
  return (
    <>
      <AppRoutes />
      <ToastContainer />
    </>
  );
}

export default App;

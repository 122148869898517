import { createAsyncThunk, } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axios-client";

export const sendInquiryToMail = createAsyncThunk(
    'send-inquiry-to-mail',
    async (data) => {
        let args = {
            url: `/mail/send-project-inquiry`,
            method: 'post',
            data: data
        }
        return axiosClient(args)
    }
)
export const sendCareerMail = createAsyncThunk(
    'send-career-mail',
    async (data) => {
        let args = {
            url: `/mail/career-mail`,
            method: 'post',
            data: data
        }
        return axiosClient(args)
    }
)
export const sendAutoReply = createAsyncThunk(
    'send-auto-reply',
    async (data) => {
        let args = {
            url: `/mail/send-auto-reply`,
            method: 'post',
            data: data
        }
        return axiosClient(args)
    }
)
export const sendContactMail = createAsyncThunk(
    'send-contact-mail',
    async (data) => {
        let args = {
            url: `/mail/recive-mail`,
            method: 'post',
            data: data
        }
        return axiosClient(args)
    }
)


import React from 'react';
import { websiteConfig } from '../../constants/websiteConfigration.constants';
import '../../assets/style/scss/main.css'

function NavigationBar() {
  return (
    <nav className="top-nav navbar navbar-expand-lg" data-bs-theme="dark" id='top-navbar'>
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img className="w-50 ms-3" src={websiteConfig?.mainLogo} alt="not found" />
        </a>
        <button className='navbar-toggler'
          id='btn-test'
          type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className={`nav-link`} href="#work">Work</a>
              <ul className=' d-lg-none' id='menu-list-sm'>
                <li><a className={`nav-link`} href="#company">Company</a></li>
                <li><a className={`nav-link`} href="#team">Team</a></li>
                <li><a className={`nav-link`} href="#career">Career</a></li>
              </ul>
            </li>
              {/* <a className={`nav-link`} href="#blog">Blog</a>  */}

            <li className="nav-item dropdown d-none d-lg-block" id='menu-list-lg'>
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">About</a>
              <ul className="dropdown-menu bg-dark-blue-acent">
                <li><a className={`dropdown-item nav-link`} href="#company">Company</a></li>
                <li><a className={`dropdown-item nav-link`} href="#team">Team</a></li>
                <li><a className={`dropdown-item nav-link`} href="#career">Career</a></li>
                {/* <li><a className={`dropdown-item nav-link`} href="#blog">Blog</a></li> */}
              </ul>
            </li>
            <li className="nav-item">
              <form className="container-fluid justify-content-start" style={{padding:'0px 8px'}}>
                <a href='#/contact' className={`btn btn-sm btn-light mt-1 me-2 text-uppercase`} type="button">Contact</a>
              </form>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}


document.body.onclick = function (event) {
  if (document.querySelector('.navbar-collapse')) {
    if (!event.target.closest('.navbar')) {
      document.querySelector('.navbar-collapse').classList.remove('show');
      document.querySelector('.navbar-toggler').setAttribute('aria-expanded', 'false');
    }
  }
};

// close navbar when scrolling
window.onscroll = function () {
  if (document.querySelector('.navbar-collapse')) {
    document.querySelector('.navbar-collapse').classList.remove('show');
    document.querySelector('.navbar-toggler').setAttribute('aria-expanded', 'false');
    //  get the active element and call blur
    document.activeElement.blur();
  }
}

export default NavigationBar;

import React from 'react'
import { Modal } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import '../../assets/style/component.style.css'
function PopUpModel(props) {
    return (
        <>
            <Modal show={props.show} size='lg' centered className='p-0 m-0 center' onHide={props.handleClose}>
                <Modal.Header closeButton className='bg-dark border-0'>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center align-items-center bg-dark flex-column'>
                    {props?.children?.props?.children}
                    {props.showIframe && props.videoUrl ?
                        <ReactPlayer
                            height={'calc(50vh)'}
                            width={'100%'}
                            playing={true}
                            controls={true}
                            url={`${props.videoUrl}?playsinline=1&iv_load_policy=3&rel=0&showinfo=0&controls=1&fs=0&start=0&autoplay=1&enablejsapi=1&widgetid=1`}
                        />
                        : ''
                    }
                </Modal.Body>

            </Modal>
        </>
    )
}
export default PopUpModel

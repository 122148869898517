import React from 'react'
import { scrollTop } from '../../assets/js/main'
import { FaArrowUp } from 'react-icons/fa'

function ScrollTopBtn() {
    return (
        <FaArrowUp  id='button' className='btn btn-primary' onClick={(e) => { scrollTop(e) }} />
    )
}

export default ScrollTopBtn
import React from 'react'
import { Stack } from 'react-bootstrap'
import { FaFacebook, FaLinkedin, FaPauseCircle, FaRegPlayCircle, FaYoutube } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { colorConfig } from '../../constants/websiteConfigration.constants'

function BlogFooterSection() {
  return (
    <footer
      className={`footer mt-auto py-2 ${colorConfig?.primaryColor}`}
      style={{ padding: "10px 0", textAlign: 'center', color: '#fff' }}
    >
      <Stack
        direction="horizontal"
        className="text-white ps-4 pe-4 d-flex flex-column flex-md-row justify-content-between align-items-center"
        gap={3}
      >
        <div className="p-2">
          <div>
            © {new Date().getFullYear()} Copyright: &nbsp;
            <a className="text-reset fw-bold" href="https://aurainteract.com/">aurainteract.com</a>
          </div>
        </div>
        <div className=' d-flex flex-wrap text-center justify-content-center align-items-center'>
          <div className="p-1">
            Get connected with us on social networks:
          </div>
          <div className="d-flex justify-content-center justify-content-md-end ms-2 ms-md-2 ms-sm-0">
            <a
              href="https://www.youtube.com/@aurainteract1901"
              target="_blank"
              rel="noreferrer"
              className="me-4 text-reset text-decoration-none"
            >
              <FaYoutube />
            </a>
            <a
              href="https://www.linkedin.com/company/aurainteract/"
              target="_blank"
              rel="noreferrer"
              className="me-4 text-reset text-decoration-none"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://m.facebook.com/aurainteractcom/"
              target="_blank"
              rel="noreferrer"
              className="me-4 text-reset text-decoration-none"
            >
              <FaFacebook />
            </a>
            <a
              href="https://twitter.com/i/flow/login?redirect_after_login=%2FAuraInteract"
              target="_blank"
              rel="noreferrer"
              className="me-4 text-reset text-decoration-none"
            >
              <FaXTwitter />
            </a>
          </div>
        </div>
      </Stack>
    </footer>
  )
}

export default BlogFooterSection
import React from 'react'

export function calculateReadingTime(content) {
    // Average reading speed in words per minute
    const wordsPerMinute = 200;

    // Calculate the number of words in the content
    const words = content.split(/\s+/).length;

    // Calculate the reading time in minutes
    const readingTimeMinutes = Math.ceil(words / wordsPerMinute);

    return readingTimeMinutes;
}
function BlogCardSection({ data }) {

    return (
        <>
            {data && data.length > 0 ?
                data.map((blog, index) => (
                    <div className="_al _cw" key={index}>
                        <div className="_pw-homefeed-item _kx _ky _kz _la _lb _lc _ld">
                            <div className="_cs _n _o _le _lf _lg _lh _li _lj">
                                <div className="_fb _al _az _ah">
                                    <div className="_lk _ah">
                                        <div className="_n _o _bc"><a tabIndex="0" rel="noopener follow"
                                           href={`#read-blog/${blog?.slug}`}>
                                            <div className="_ah _dq">
                                                <img src={`${process.env.REACT_APP_IMAGE_URL_S3}${blog?.author?.profile_pic}`} className="_ah _cs _jw _hf _hg _fe" alt={'Author Name'} width="20" height="20" loading="lazy" />
                                                <div className="_jv _jw _ah _hf _hg _jx _ao _jy _jz"></div>
                                            </div>
                                        </a>
                                            <div className="_ka _n _o _fi">
                                                <div className="_n _o"><a
                                                    className="_bd _be _bf _bg _bh _bi _bj _bk _bl _bm _bn _bo _bp _bq _br"
                                                    rel="noopener follow"
                                                    href={`#read-blog/${blog?.slug}`}>
                                                    <h4
                                                        className="_by _fy _jj _kb _dp _kc _kd _ke _kf _kg _kh _ki _cb _kj">
                                                        {blog?.author?.name}</h4>
                                                </a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <a className="_bd _be _bf _bg _bh _bi _bj _bk _bl _bm _bn _bo _bp _bq _br" href={`/#read-blog/${blog?.slug}`} rel="noopener follow">
                                        <h2
                                            className="blog-title _by _kk _ll _lm _ln _lo _lp _lq _lr _ls _lt _lu _lv _lw _lx _ly _lz _ma _mb _mc _md _me _dp _kd _ke _km _kg _ki _cb">
                                            {blog?.title}</h2>
                                        <div className="_mf _ah _g">
                                            <h3 className="_by _b _et _ca _dp _kl _kd _ke _km _kg _ki _iw">{blog?.subTitle}</h3>
                                        </div>
                                    </a>
                                    <div className="_mg _mh _mi _mj _mk _n _ml _le">
                                        <div className="_az _n _o d-flex flex-wrap ">
                                            <span className="_pw-published-date _ku">
                                                <span className="_by _b _jj _ca _iw">
                                                    <span>
                                                        {new Date(blog?.uploadedDate).toLocaleString('default', { month: 'long' })} &nbsp;
                                                        {new Date(blog?.uploadedDate).getDate()},&nbsp;
                                                        {new Date(blog?.uploadedDate).getFullYear()}
                                                    </span>
                                                </span>
                                            </span>
                                            <div className="_kn _ko _cw _ah _dq _mm" aria-hidden="true">
                                                <span className="_ah" aria-hidden="true"><span className="_by _b _bz _ca _iw">·</span></span>
                                            </div>
                                            <span className="_ku">
                                                <span className="_pw-reading-time _by _b _jj _ca _iw">{blog?.content ? `${calculateReadingTime(blog?.content)} min read` : ''}</span>
                                            </span>
                                            <div className="_kn _ko _cw _ah _dq _mm" aria-hidden="true">
                                                <span className="_ah" aria-hidden="true"><span className="_by _b _bz _ca _iw">·</span></span>
                                            </div>
                                            <div className="_ce _dq _fe _ie _kr _ks _kt _by _b _bz _ca _ku text-capitalize bg-dark text-white border border-dark">
                                                {blog?.category}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <a className="_bd _be _bf _bg _bh _bi _bj _bk _bl _bm _bn _bo _bp _bq _br" href={`#read-blog/${blog?.slug}`} rel="noopener follow">
                                    <img src={`${process.env.REACT_APP_IMAGE_URL_S3}${blog?.thumbnailImage}`} alt="Thumbnail-alt" className='_thumbnail-image' loading="lazy" />

                                </a>
                            </div>
                        </div>
                    </div>
                )) : <h2 className=' text-center text-white'>No blog found</h2>
            }
        </>
    )
}

export default BlogCardSection
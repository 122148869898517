import React, { useEffect } from 'react'
import NavigationBar from '../../components/common/navigation-bar'
import SectionFooter from '../../components/common/section-footer'
import { useSelector } from 'react-redux'
import SectionHaveAnIdea from '../../components/section-have-an-idea'
import SectionOurClients from '../../components/section-our-clients'
import Header from '../../components/common/headerSection'
import { CompanyPageHeader } from '../../constants/header.constants'
import { colorConfig, websiteConfig } from '../../constants/websiteConfigration.constants'

function Company() {

    const settingState = useSelector((state) => state.websiteSettings)
    //const [companyData, setCompanyData] = useState({});

    useEffect(() => {
        try {
            if (!settingState.loading) {
                //setCompanyData(settingState?.websiteSettings)
            }
        } catch (error) {
            console.log("Error", error);
        }
    }, [settingState])
    return (
        <div className={`${colorConfig?.secondaryColor}`}>
            <NavigationBar />
            <Header props={CompanyPageHeader} />
            <div className={`company ${colorConfig?.secondaryColor} text-light px-4 py-5 text-center`}>
                <div className="py-5">
                    <img src={websiteConfig?.logoTagLine} className="w-25 my-5 pb-5" id="company-logo" alt=""/>
                    <div className="col-lg-8 mx-auto text-start fw-light fs-6">
                        <p style={{ whiteSpace: 'pre-line' }}> {websiteConfig?.companyExcerpt}</p>
                    </div>
                </div>
            </div>
            <div className={`py-5 ${colorConfig?.secondaryColor}`}>
                <SectionHaveAnIdea bgColor='' />
            </div>
            <div className='col-xl-8 mx-auto clients-section'>
                <div className="b-divider-10" ></div>  {/* for spacing */}
                <SectionOurClients bgColor={`${colorConfig?.secondaryColor}`} />
                <div className="b-divider-10" ></div>  {/* for spacing */}
            </div>
            <SectionFooter />
        </div>
    )
}

export default Company
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { colorConfig } from '../constants/websiteConfigration.constants'

function SectionJoinOurTeam() {
    const navigator=useNavigate()
    return (
        <div className={`${colorConfig?.secondaryColor} text-secondary px-4 py-5`}>
            <div className="container col-xxl-8 px-4 py-5">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6">
                        {/* <img src="/images/team/team-chair.jpg" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/> */}
                        <img src={require("../assets/images/team/team-chair.png")} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
                    </div>
                    <div className="col-lg-6">
                        <h1 className="display-5 noto-font fw-thin lh-1 mb-3 text-white">We are <strong><i>hiring</i></strong>!</h1>
                        <p className="lead">Join our team to be a part of creating innovative, immersive experiences that inspire and transform.</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <button type="button" className="btn btn-primary btn-lg px-4 me-md-2"  onClick={()=>{navigator('/career') }}>Apply now</button>
                            {/* <!-- <button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button> --> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionJoinOurTeam


import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { FaDiscord, FaPlayCircle } from 'react-icons/fa';
import PopUpModel from '../../ui-components/ui-common/popUpModel';
import { strings } from '../../constants/strings.constans';
import { websiteConfig } from '../../constants/websiteConfigration.constants';

function HeroPage({ props }) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => {
        setShow(true)
    };
    return (

        <div className="video-background-holder bg-dark-blue">
            <PopUpModel show={show} showIframe={props?.showIframe} videoUrl={props?.external_video} handleClose={handleClose} />
            {props ?
                <>
                    <div className="video-background-overlay"></div>
                    <video
                        id="video-player"
                        className="video"
                        src={`${process.env.REACT_APP_VIDEO_URL}${props?.background_video}`}
                        autoPlay={true}
                        muted={true}
                        playsInline={true}
                        loop={true}
                    />
                    <div className="video-background-content container h-100">
                        <div className="d-flex h-100 text-center align-items-center">
                            <div className="w-100 text-white">
                                {
                                    props?.showLogo &&
                                    <img src={websiteConfig?.mainLogo} className='w-30 mb-5' alt="Logo" />
                                }

                                <h1 className="display-4 mb-5">
                                    <span className="fw-lighter">{props?.titleSm}</span>
                                </h1>
                                <h1 className="display-3 fw-bold">{props?.title}</h1>
                                <p className="lead display-6 fw-lighter">{props?.subTitle}</p>
                                <p className="lead mb-5 lh-6 col-lg-8 mx-auto fw-lighter">{props?.excerpt}</p>
                                {
                                    props?.external_video &&
                                    <>
                                        <Button variant="light" className="btn btn-light btn-lg px-4 text-uppercase mt-2"
                                            onClick={handleShow}
                                        >
                                            Watch The Video<FaPlayCircle className="ps-2 fs-4" style={{ marginTop: "-3px" }} />
                                        </Button>
                                        {
                                            props?.slug === strings.GILLI_DANDA_SLUG && 
                                            <a className="btn btn-warning btn-lg px-4 mt-2 text-uppercase ms-4" target='_blank' href={'https://discord.gg/tjx8jFk3u6'} rel="noreferrer" >
                                                Join Discord<FaDiscord className="ps-2 fs-4" style={{ marginTop: "-3px" }} />
                                            </a>
                                        }

                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </>
                : ''}
        </div>
    )
}

export default HeroPage
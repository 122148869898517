import React, { useEffect, useState } from 'react'
import "../../assets/style/scss/main-detail-blog.css"
import BlogCategorySection2 from '../../components/Blogs/blog-category-section'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchBlogBySlug } from '../../redux/slices/blogsAPISlice'
import parse from 'html-react-parser';
import { Spinner } from 'react-bootstrap'
import { colorConfig } from '../../constants/websiteConfigration.constants'
import Header from '../../components/common/headerSection'
import BlogFooterSection from '../../components/Blogs/blog-footer-section'
import BlogNavbar from '../../components/Blogs/blog-navbar'


const splitText = (text, from, to, isHTMLContent) => [
    text.slice(0, from),
    text.slice(from, to),
    text.slice(to)
];

function calculateReadingTime(content) {
    const wordsPerMinute = 200;

    const words = content.split(/\s+/).length;

    // Calculate the reading time in minutes
    const readingTimeMinutes = Math.ceil(words / wordsPerMinute);

    return readingTimeMinutes;
}

function ReadBlog() {
    const [speakingContent, setSpeakingContent] = useState([])
    const [currentSpeaking, setCurrentSpeaking] = useState(0)
    const [voices, setvoices] = useState([])
    const [isSpeaking, setIsSpeaking] = useState(false)
    const [strContent, setStrContent] = useState()
    const [isPause, setIsPause] = useState(false)
    const [blogs, setBlogs] = useState([])
    const [blogsContent, setBlogContent] = useState('')
    const [categoriesLength, setCategoriesLength] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const params = useParams()

    const [highlightSection, setHighlightSection] = React.useState({
        from: 0,
        to: 0
    });

    const HighlightedText = ({ text, from, to, isHTMLContent }) => {
        const [start, highlight, finish] = splitText(text, from, to, isHTMLContent);
        const highlightParagraph = `
                ${start}
                <span className='highlight-bg-color'>${highlight}</span>
                ${finish}`
        return (
            parse(highlightParagraph)
        )
    };

    useEffect(() => {
        setIsLoading(true)
        getBlogBySlug()
    }, [])

    const getBlogBySlug = async () => {
        setIsLoading(true)
        const response = await dispatch(fetchBlogBySlug(params.slug))
        if (response?.payload?.data?.status === 200) {
            const data = response.payload.data.data[0]
            setBlogs(data)
            setSpeakingContent([
                data?.title,
                data?.subTitle,
                data?.content
            ])
            setBlogContent(data.content)
        }
        if (response?.payload?.data?.error) {
            console.log("Error", response?.payload?.data?.message)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        const sys = window.speechSynthesis
        // console.log(sys)
        if (!sys) {
            console.log("Text to speech is not support in your browser")
            return;
        }
        if (isSpeaking) {
            handlePlayReset()
        } else {
            sys.cancel()
            setIsSpeaking(false)
        }
    }, [currentSpeaking])


    const handlePlayReset = () => {
        const synth = window.speechSynthesis;
        if (!synth) {
            alert("Text to speech is not support in your browser")
            return;
        }
        if (synth.speaking) {
            setIsSpeaking(false);
            setIsPause(false)
            setCurrentSpeaking(0)
            setHighlightSection({ from: 0, to: 0 });
            synth.cancel();
        } else {
            setIsSpeaking(true);

            var html = speakingContent[currentSpeaking];
            var div = document.createElement("div");
            div.innerHTML = html;
            const speakText = div.innerText
            setStrContent(speakText)

            let utterance = new SpeechSynthesisUtterance(speakingContent[currentSpeaking]);
            let voices = speechSynthesis.getVoices()

            setvoices(voices)
            utterance.voice = voices.filter((f) => f.lang === 'en-US')[0]  //Complete work on voice:-Microsoft David - English (United States)
            utterance.addEventListener("boundary", (event) => {
                const { charIndex, charLength } = event;
                setHighlightSection({ from: charIndex, to: charIndex + charLength });
            });
            utterance.addEventListener("end", () => {
                if (currentSpeaking !== speakingContent.length - 1) {
                    setCurrentSpeaking(currentSpeaking + 1)
                    setHighlightSection({ from: 0, to: 0 });
                } else {
                    setIsSpeaking(false)
                }
            });
            utterance.onmark = (event) => {
                console.log(`A mark was reached: ${event.name}`);
            };
            synth.speak(utterance);
        }
    };


    const handlePauseResume = () => {
        const synth = window.speechSynthesis;
        if (synth && isPause) {
            synth.resume();
            setIsPause(false)
        } else {
            synth.pause();
            setIsPause(true)
        }
    }

    const handleCategoriesLength = (length) => {
        setCategoriesLength(length);
    };

    return (
        <div className={`a b ${colorConfig?.secondaryColor}`} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <div className="d e f g h i j k"></div>
            <div className={`${isLoading ? colorConfig?.secondaryColor : `l ${colorConfig?.secondaryColor}`}`}>
                {/* <NavigationBar /> */}
                {/* <BlogNavBarSection/> */}
                <BlogNavbar showSearch={false} showHomeIcon={true} />
                <Header props={{ title: blogs?.title, image: `${process.env.REACT_APP_IMAGE_URL_S3}${blogs?.thumbnailImage} `, description: blogs?.subTitle, url: `#read-blog/${blogs?.slug}` }} />

                {/* END NAVBAR */}
                {isLoading ?
                    < Spinner variant='white' animation="border" role="status" className=' d-flex m-auto' >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    <div className="l">
                        <div className="ft fu fv fw fx l">
                            <div className="ab ca">
                                <div className="ch bg fy fz ga gb"></div>
                            </div>
                            <article>
                                <div className="l">
                                    <div className="l"><span className="l"></span>
                                        <section>
                                            <div>
                                                <div className="fr gh gi gj gk gl"></div>
                                                <div className="gm gn go gp gq">
                                                    <div className="ab ca">
                                                        <div className="ch bg fy fz ga gb">
                                                            <div>
                                                                <h1 id="blog-title"
                                                                    className="pw-post-title gr gs gt be gu gv gw gx gy gz ha hb hc hd he hf hg hh hi hj hk hl hm hn ho hp bj"
                                                                    data-testid="storyTitle">
                                                                    {/* {blogs?.title} */}
                                                                    {/* <TextToSpeech text={blogs?.title} /> */}
                                                                    {speakingContent.length > 0 && currentSpeaking === 0 ? <HighlightedText text={blogs?.title} {...highlightSection} /> : blogs?.title}
                                                                </h1>
                                                            </div>
                                                            <div>
                                                                <h2 id="ec98"
                                                                    className="pw-subtitle-paragraph hq gs gt be b hr hs ht hu hv hw hx hy hz ia ib ic id ie if cp dt">
                                                                    <strong className="al">
                                                                        <em className="ig">
                                                                            {/* {blogs?.subTitle} */}
                                                                            {speakingContent.length > 0 && currentSpeaking === 1 ? <HighlightedText text={blogs?.subTitle} {...highlightSection} /> : blogs?.subTitle}
                                                                        </em>
                                                                    </strong>
                                                                </h2>

                                                                {/* AUTHOR PROFILE SECTION */}
                                                                <div className="ih ii ij ik il">
                                                                    <div className="speechify-ignore ab co" id="profile-section">
                                                                        <div className="speechify-ignore bg l">
                                                                            <div className="im in io ip iq ab">
                                                                                <div>
                                                                                    <div className="ab ir">
                                                                                        <a rel="noopener follow" href={`/#read-blog/${blogs?.slug}`}>
                                                                                            <div>
                                                                                                <div className="bl"
                                                                                                    aria-hidden="false">
                                                                                                    <div
                                                                                                        className="l is it bx iu iv">
                                                                                                        <div className="l fi">
                                                                                                            <img src={`${process.env.REACT_APP_IMAGE_URL_S3}${blogs?.author?.profile_pic}`} className="l fc bx dc dd cw" alt={'Author Name'} width="44"
                                                                                                                height="44"
                                                                                                                loading="lazy"
                                                                                                                data-testid="authorPhoto" />

                                                                                                            <div
                                                                                                                className="iw bx l dc dd fr n ix fs">
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </a></div>
                                                                                </div>
                                                                                <div className="bm bg l">
                                                                                    <div className="ab">
                                                                                        <div style={{ flex: "1" }}>
                                                                                            <span className="be b bf z bj">
                                                                                                <div className="iy ab q">
                                                                                                    <div className="ab q iz">
                                                                                                        <div className="ab q">
                                                                                                            <div>
                                                                                                                <div className="bl"
                                                                                                                    aria-hidden="false">
                                                                                                                    <p
                                                                                                                        className="be b ja jb bj">
                                                                                                                        <a className="af ag ah ai aj ak al am an ao ap aq ar jc"
                                                                                                                            data-testid="authorName"
                                                                                                                            rel="noopener follow"
                                                                                                                            href={`/#read-blog/${blogs?.slug}`}>{blogs?.author?.name}</a>
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="l jj">
                                                                                        <span className="be b bf z dt">
                                                                                            <div className="ab cm jk jl jm">
                                                                                                <span className="be b bf z dt">
                                                                                                    <div className="ab ae"><span
                                                                                                        data-testid="storyReadTime">{blogs?.content ? `${calculateReadingTime(blogs?.content)} min read` : ''}</span>
                                                                                                        <div className="jn jo l"
                                                                                                            aria-hidden="true">
                                                                                                            <span className="l"
                                                                                                                aria-hidden="true"><span
                                                                                                                    className="be b bf z dt">·</span></span>
                                                                                                        </div><span
                                                                                                            data-testid="storyPublishDate">  {new Date(blogs?.uploadedDate).toLocaleString('default', { month: 'long' })} &nbsp;
                                                                                                            {new Date(blogs?.uploadedDate).getDate()},&nbsp;
                                                                                                            {new Date(blogs?.uploadedDate).getFullYear()}</span>
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* SOCIAL AND SPEECH SETCION */}

                                                                            {/* <div
                                                                                className="ab co jp jq jr js jt ju jv jw jx jy jz ka kb kc kd ke">
                                                                                <div
                                                                                    className="d-flex flex-wrap gap-1 justify-content-center ab q kf kg kh ki kj kk kl km ko kp kq kr ks kt">
                                                                                    <div className="lw k j i d"></div>
                                                                                    <div className='d-flex flex-wrap gap-1'>
                                                                                        <div className="fc ly cm">
                                                                                            <div className="l ae">
                                                                                                <div className="ab ca">
                                                                                                    <div
                                                                                                        className="lz ma mb mc md me ch bg">
                                                                                                        <div className="ab">
                                                                                                            <div className="bl bg"
                                                                                                                aria-hidden="false">
                                                                                                                <div>
                                                                                                                    <div className="bl"
                                                                                                                        aria-hidden="false">
                                                                                                                        <div className="speak-test bg-white mt-8">
                                                                                                                        </div>
                                                                                                                        <button
                                                                                                                            onClick={() => handlePlayReset()}
                                                                                                                            aria-label={isSpeaking ? 'Reset' : 'Listen'}
                                                                                                                            title={isSpeaking ? 'Reset' : 'Listen'}
                                                                                                                            data-testid="audioPlayButton"
                                                                                                                            className="af fj ah ai aj ak al mf an ao ap ew mg mh lv mi mj mk ml mm s mn mo mp mq mr ms mt u mu mv mw">
                                                                                                                            {!isSpeaking ? <FaRegPlayCircle style={{ width: '24px', height: '24px', color: 'white' }} /> : <MdOutlineReplay style={{ width: '24px', height: '24px', color: 'white' }} />}
                                                                                                                            <div
                                                                                                                                className="j i d">
                                                                                                                                <p
                                                                                                                                    className="be b bf z dt">
                                                                                                                                    {isSpeaking ? 'Reset' : 'Listen'}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="fc ly cm">
                                                                                            <div className="l ae">
                                                                                                <div className="ab ca">
                                                                                                    <div
                                                                                                        className="lz ma mb mc md me ch bg">
                                                                                                        <div className="ab">
                                                                                                            <div className="bl bg"
                                                                                                                aria-hidden="false">
                                                                                                                <div>
                                                                                                                    <div className="bl"
                                                                                                                        aria-hidden="false">
                                                                                                                        <div className="speak-test bg-white mt-8">
                                                                                                                        </div>
                                                                                                                        {isSpeaking &&
                                                                                                                            <button
                                                                                                                                onClick={() => handlePauseResume()}
                                                                                                                                aria-label="Listen"
                                                                                                                                title={isPause ? 'Resume' : 'Pause'}
                                                                                                                                data-testid="audioPlayButton"
                                                                                                                                className="af fj ah ai aj ak al mf an ao ap ew mg mh lv mi mj mk ml mm s mn mo mp mq mr ms mt u mu mv mw">
                                                                                                                                {!isPause ? <FaPauseCircle style={{ width: '24px', height: '24px', color: 'white' }} /> : <FaRegPlayCircle style={{ width: '24px', height: '24px', color: 'white' }} />}
                                                                                                                                <div
                                                                                                                                    className="j i d">
                                                                                                                                    <p
                                                                                                                                        className="be b bf z dt">
                                                                                                                                        {isPause ? 'Resume' : 'Pause'}
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </button>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bl" aria-hidden="false"
                                                                                        aria-describedby="postFooterSocialMenu"
                                                                                        aria-labelledby="postFooterSocialMenu">
                                                                                        <div>
                                                                                            <div className="bl"
                                                                                                aria-hidden="false">
                                                                                                <button
                                                                                                    aria-controls="postFooterSocialMenu"
                                                                                                    title='Share'
                                                                                                    aria-expanded="false"
                                                                                                    aria-label="Share Post"
                                                                                                    data-testid="headerSocialShareButton"
                                                                                                    className="af fj ah ai aj ak al mf an ao ap ew mg mh lv mi mj mk ml mm s mn mo mp mq mr ms mt u mu mv mw text-white">
                                                                                                    <IoShareOutline style={{ width: '24px', height: '24px', color: 'white' }} />
                                                                                                    <div className="j i d">
                                                                                                        <p
                                                                                                            className="be b bf z dt">
                                                                                                            Share</p>
                                                                                                    </div>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}

                                                                            {/* END SOCIAL AND SPEECH SECTION */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* END PROFILE SECTION */}
                                                            </div>

                                                            {/* MAIN BLOG CONTENT SECTION */}
                                                            {/* <div className='blog-content'> */}
                                                            <div className='blog-contents-'>


                                                                <figure className="na nb nc nd ne nf mx my paragraph-image mb-5 mt-5">
                                                                    <div role="button" tabIndex="0" className="ng nh fi ni bg nj">
                                                                        <div className="mx my mz">
                                                                            <picture>
                                                                                <img alt="" className="bg me nk " width="700"
                                                                                    src={`${process.env.REACT_APP_IMAGE_URL_S3}${blogs?.thumbnailImage}`}
                                                                                    height="368" loading="eager"
                                                                                    role="presentation" />
                                                                            </picture>
                                                                        </div>
                                                                    </div>
                                                                </figure>
                                                                {!isLoading &&
                                                                    <div className='blog-contents text-white'>
                                                                        {/* <TextToSpeech text={parse(blogsContent)} speak={strContent} isHTMLContent={true}/> */}
                                                                        {speakingContent.length > 0 && currentSpeaking === 2 ?
                                                                            <HighlightedText isHTMLContent={true} text={blogsContent} {...highlightSection} />
                                                                            : parse(blogsContent)}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </article>
                            <div className="ab ca">
                                <div className="ch bg fy fz ga gb"></div>
                            </div>
                        </div>

                        {/* CATEGORIES SECTION */}
                        <div className="ab ca">
                            <div className="ch bg fy fz ga gb">
                                <div className="rb rc ab jm">
                                    <BlogCategorySection2 onCategoriesLengthChange={handleCategoriesLength} />
                                </div>
                            </div>
                        </div>
                        {/* END-- */}

                        <div className="l"></div>
                        <div className="sc sd se sf sg l">
                            <div className="ab ca">
                                <div className="ch bg fy fz ga gb">
                                    <div className="ck ab sh co">
                                        <div className="ab ir"><a rel="noopener follow"
                                            href={`/#read-blog/${blogs?.slug}`}>
                                            <div className="l si sj bx sk iv">
                                                <div className="l fi"><img alt="profile picture" className="l fc bx sl sm cw"
                                                    src={`${process.env.REACT_APP_IMAGE_URL_S3}${blogs?.author?.profile_pic}`}
                                                    width="72" height="72" loading="lazy" />
                                                    <div className="iw bx l sl sm fr n ix fs"></div>
                                                </div>
                                            </div>
                                        </a></div>
                                        <div className="j i d">
                                        </div>
                                    </div>
                                    <div className="ab cm co">
                                        <div className="l">
                                            <div className="ab q">
                                                <a className="af ag ah ai aj ak al am an ao ap aq ar as at ab q"
                                                    rel="noopener follow"
                                                     href={`/#read-blog/${blogs?.slug}`}>
                                                    <h2 className="pw-author-name be ta tb tc td bj">
                                                        <span className="gm">Written by {blogs?.author?.name}</span>
                                                    </h2>
                                                </a></div>
                                        </div>
                                    </div>
                                    <div className="tf bg th ti tj tk tl"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
                <BlogFooterSection/>
        </div>
    )
}

export default ReadBlog
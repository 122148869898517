import React from 'react'
import '../assets/style/section-our-clients.css'
import { Carousel } from 'react-bootstrap'
import hal from '../assets/images/white/hal.png';
import viewerReady from '../assets/images/white/viewer-ready.png';
import cbet from '../assets/images/white/cbet.png';
import nvrt from '../assets/images/white/nvrt.png';
import sharevision from '../assets/images/white/sharevision.png';
import foster from '../assets/images/white/foster.png';
import amc from '../assets/images/white/amc.png';
import gdg from '../assets/images/white/gdg.png';
import ideal from '../assets/images/white/ideal.png';
import intellimedia from '../assets/images/white/intellimedia.png';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const logoImages = [
  hal, viewerReady, cbet, nvrt, sharevision,
  foster, amc, gdg, ideal, intellimedia
];

function SectionOurClients(props) {
  const isMobile = window.innerWidth <= 768; // Assuming mobile breakpoint is 768px

  const slides = [];
  if (isMobile) {
    for (let i = 0; i < logoImages.length; i++) {
      slides.push(logoImages.slice(i, i + 1));
    }
  } else {
    for (let i = 0; i < logoImages.length; i += 3) {
      slides.push(logoImages.slice(i, i + 3));
    }
  }

  return (
    <section className={`${props?.bgColor} row`}>
      <h3 className=' col-10 container fw-bold text-white container display-3 mb-5'>Our Clients</h3>
      <Carousel data-bs-theme="dark"
        nextIcon={<FaArrowRight className='w-25 h-25' />}
        prevIcon={<FaArrowLeft className='w-25 h-25' />}
        indicators={false} slide={true} controls={true} interval={3000}>
        {slides.map((slide, index) => (
          <Carousel.Item key={index}>
            <div className='container d-none d-lg-flex justify-content-around' style={{ padding: '0 80px' }}>
              {slide.map((img, idx) => (
                <img
                  key={idx}
                  className="d-block w-25 h-25"
                  src={img}
                  alt={`Slide ${index + 1}`}
                />
              ))}
            </div>
            <div className='d-lg-none' style={{ padding: '0 40px' }}>
              {slide.map((img, idx) => (
                <img
                  key={idx}
                  className="d-block w-100 hidden-lg-up"
                  src={img}
                  alt={`Slide ${index + 1}`}
                />
              ))}
            </div>
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  )
}

export default SectionOurClients

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useMediaQuery
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Card } from 'react-bootstrap';
import { websiteConfig } from '../../constants/websiteConfigration.constants';
import './form.style.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userLogin } from '../../redux/atuhSlices/AuthAPI';

const Login = ({ ...others }) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [submiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const hasToken = localStorage.getItem("access_token")
    if (hasToken) navigate('/blog')
  }, [])
  

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const sendTokenToAdminpanel = () => {
    const targetOrigin = process.env.REACT_APP_BASE_URL_ADMIN_IP; // Base URL of the receiver app
    const message = { token: localStorage.getItem('access_token') };
  
    // Assuming the receiver app is already loaded in an iframe or another window
    const iframe = document.getElementById('receiver-iframe');
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(message, targetOrigin);
    } else {
      console.error('Iframe not found or not loaded.');
    }
  };
  

  //Receive token when user login into aurainteract.com login page
  useEffect(() => {
    const ReceiveAccessTokenAdminSite = (event) => {
      // Check if the message comes from the expected origin
      if (event.origin !== process.env.REACT_APP_BASE_URL_ADMIN_IP) {
        console.warn('Unexpected origin:', event.origin);
        return;
      }

      const { data } = event;
      if (data && data.token) {
        // localStorage.removeItem('access_token')
        localStorage.setItem('access_token', data.token)
      }
    };

    window.addEventListener('message', ReceiveAccessTokenAdminSite);

    // Clean up the event listener
    return () => {
      window.removeEventListener('message', ReceiveAccessTokenAdminSite);
    };
  }, []);

  const handleLogin = async (values) => {
    setIsSubmiting(true);
    setIsLoading(true);

    try {
      const response = await dispatch(userLogin(values));
      if (response?.payload?.data?.status === 500) {
        toast.error("Please check your mail to verify your account");
      }
      if (response?.payload?.data?.error) {
        toast.error(response?.payload?.data?.message);
      }
      if (response?.payload?.data?.status === 200) {
        if (response?.payload?.data?.data?.user?.role !== 'user') {
          sendTokenToAdminpanel()
        }

        setTimeout(() => {
          window.location = '#blog';
        }, 2000);
      }
    } catch (error) {
      console.log("Login error", error);
      toast.error(error?.message);
    }

    setIsLoading(false);
    setIsSubmiting(false);
  };

  return (
    <div id='login-body' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

      {/* Send access token to admin panel if user login into main website. */}
      <iframe
        title='send-token to admin panel'
        id="receiver-iframe"
        src={`${process.env.REACT_APP_BASE_URL_ADMIN_IP}/pages/login`}
        className='d-none'
      ></iframe>
      {/*  */}

      <Card className="m-auto p-3 d-flex justify-content-center rounded-3 border-0" style={{ width: matchDownSM ? '90vw' : '50vh' }}>
        <Card.Img variant="top" src={websiteConfig.logoTagBlackLine} className='w-25 m-auto' />
        <Card.Body>
          <Card.Title className='text-primary text-center m-3 title'>Hi, Welcome Back</Card.Title>
          <Card.Text className='text-center m-3 paragraph'>
            Enter your credentials to continue
          </Card.Text>

          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => {
              handleLogin(values);
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
              <form noValidate onSubmit={handleSubmit} {...others}>
                <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login">Email Address / Username</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="email"
                    className='form-email-field'
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Email Address / Username"
                    inputProps={{}}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl className="mt-3" fullWidth error={Boolean(touched.password && errors.password)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password-login"
                    type={showPassword ? 'text' : 'password'}
                    className='form-password-field'
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    inputProps={{}}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText error id="standard-weight-helper-text-password-login">
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>

                <Box sx={{ mt: 2 }}>
                  <Button disabled={isLoading} fullWidth size="large" type="submit" variant="contained" style={{ backgroundColor: '#1e88e5' }}>
                    Sign in
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <hr />
          <div className="m-1 p-1 border-top-3 border border-black border-0">
            <div className="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-direction-xs-column MuiGrid-grid-xs-12 css-clh38r-MuiGrid-root">
              <center>
                <a className="sign-up text-center" href={`${process.env.REACT_APP_BASE_URL_ADMIN_IP}/pages/register`}>
                  Don't have an account?
                </a>
              </center>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axios-client";

export const fetchCareerList = createAsyncThunk(
    'fetchCareerList',
    async () => {
        let args = {
            url: "/career-json/get-career-list",
            method: "get",
        };
        return axiosClient(args)
    }
)
export const fetchListBySlug = createAsyncThunk(
    'fetchListBySlug',
    async (slug) => {
        let args = {
            url: `/career-json/get-career-detail/${slug}`,
            method: "get",
        };
        return axiosClient(args)
    }
)

// export const addEmployee = createAsyncThunk(
//     'addEmployee',
//     async (employeeData) => {
//         let args = {
//             url: '/employees',
//             method: "post",
//             data: employeeData
//         };
//         return axiosClient(args)
//     }
// )


const careerSlice = createSlice({
    name: "career",
    initialState: {
        loading: false,
        career: [],
        careeError: null
    },
    extraReducers: (builder) => {
        builder

            //GET ALL CAREER LIST
            .addCase(fetchCareerList.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCareerList.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload?.data?.status === 200) {
                    state.career = action.payload.data.data;
                    state.careeError = null
                }else if (action.payload?.data?.error) {
                    state.careeError = action.payload.data.message
                    console.log("Error", action.payload.data);
                }
            })
            .addCase(fetchCareerList.rejected, (state, action) => {
                state.loading = false;
                state.careeError =  `Fetching Career Error ${action.error ? action.error.message : "Unknown Error"}`;
            })

            //ADD Work
            // .addCase(addEmployee.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(addEmployee.fulfilled, (state, action) => {
            //     state.loading = false;
            //     if (action.payload.status == 200) {
            //         state.employees.push(action.payload.data)
            //     }
            //     state.employeeError = null
            // })
            // .addCase(addEmployee.rejected, (state, action) => {
            //     state.loading = false;
            //     state.employeeError = "Add Employee Error"
            //     if (action.payload.status == 208) {
            //         state.employeeError = "Employee name or email already exist"
            //     } else {
            //         state.employeeError = action.error.message;
            //     }
            // })
    }
})

export default careerSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

import {
    userLogin,
    logoutAction,
    verifyJWTToken
} from "./AuthAPI";

const userSlice = createSlice({
    name: "user",
    initialState: {
        loading: false,
        user: null,
        access_token: null,
        userError: null
    },
    extraReducers: (builder) => {
        builder

            //LOGIN
            .addCase(userLogin.pending, (state) => {
                state.loading = true;
                state.user = null;
                state.access_token = null;
                state.userError = null;
            })
            .addCase(userLogin.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload?.data?.status === 200) {
                    state.user = action?.payload?.data?.data?.user;
                    state.access_token = action?.payload?.data?.data?.token;
                    localStorage.setItem("access_token", action?.payload?.data?.data?.token);
                    state.userError = null
                } else if (action.payload?.data?.error) {
                    // localStorage.setItem("access_token", action?.payload?.data?.data?.token);
                    state.userError = action.payload.data.message;
                    console.log("error true", action.payload.data);
                }
            })
            .addCase(userLogin.rejected, (state, action) => {
                localStorage.removeItem('access_token')
                state.loading = false;
                state.user = null;
                state.access_token = null
                state.userError = `${action.error ? action.error.message : "Unknown Error"}`;
            })

            //VERIFY TOKEN AND UPDATE USER IN STORE
            .addCase(verifyJWTToken.pending, (state) => {
                state.loading = true;
                state.user = null;
                state.access_token = null;
                state.userError = null;
            })
            .addCase(verifyJWTToken.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload?.data?.status === 200) {
                    state.user = action?.payload?.data?.data?.user;
                    state.access_token = action?.payload?.data?.data?.token;
                    localStorage.setItem("access_token", action?.payload?.data?.data?.token);
                    state.userError = null
                } else if (action.payload?.data?.error) {
                    console.log("ERROR FROM THE SLICE->", action.payload.data);
                    // localStorage.setItem("access_token", action?.payload?.data?.data?.token);
                    state.userError = action.payload.data.message;
                    console.log("error true", action.payload.data);
                }
            })
            .addCase(verifyJWTToken.rejected, (state, action) => {
                localStorage.removeItem('access_token')
                state.loading = false;
                state.user = null;
                state.access_token = null
                state.userError = `${action.error ? action.error.message : "Unknown Error"}`;
            })

            .addCase(logoutAction.fulfilled, (state) => {
                state.loading = false;
                state.user = null;
                state.access_token = null;
                state.userError = null;
                localStorage.removeItem("access_token");
            });

    }
})

export default userSlice.reducer;
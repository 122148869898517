import React, { useEffect, useState } from 'react'
import NavigationBar from '../../components/common/navigation-bar'
import SectionFooter from '../../components/common/section-footer'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import '../../assets/js/masonry-layout'
import '../../assets/style/scss/masonry layout.css'
import '../../assets/js/isotope.pkgd'
import { Spinner } from 'react-bootstrap';
import { FaMagic } from "react-icons/fa";
import _ from 'lodash'
import ScrollTopBtn from '../../ui-components/ui-common/scrollTopBtn'
import { fetchWorkBySlug } from '../../redux/slices/workSlice'
import HeroPage from '../../components/common/heroPage'
import Header from '../../components/common/headerSection'
import { colorConfig } from '../../constants/websiteConfigration.constants'

const randoSizeImage = ["", "tall", "wide", "big"]
function WorkDetailPage() {
    const { slug } = useParams()
    //const stateData = useSelector((state) => state.works)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [heroSection, setHeroSection] = useState()
    const [page, setPage] = useState()
    const [section, setSection] = useState()
    const [featuredSection, setFeaturedSection] = useState()
    const [workImages, setWorkImages] = useState([])

    //const [show, setShow] = useState(false);
    const dispatch = useDispatch()
    //const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setIsLoading(true)

        const response = await dispatch(fetchWorkBySlug(slug))
        if (response?.payload?.data?.status === 200) {
            const data = response.payload.data.data
            setData(data)

            setHeroSection(data?.hero)
            setSection(data?.section)
            setPage(data?.page)
            setFeaturedSection(data?.featureSection)
            setWorkImages(data?.workImages)
        }
        if (response?.payload?.data?.error) {
            console.log("Error", response.payload.data.message);
        }
        setIsLoading(false)

    }

    return (
        <div className={`${colorConfig?.secondaryColor}`}>
            <NavigationBar />
            <Header props={{ title: heroSection?.heroTitle, description: heroSection?.heroExcerpt, 
                image: `${process.env.REACT_APP_IMAGE_URL}/_uploads/work/${data?.list?.thumbnail_image_url}`,
                url:`#detail/${slug}`}} />
            <ScrollTopBtn />
            <HeroPage props={{
                title: heroSection?.heroTitle, subTitle: heroSection?.heroSubTitle, excerpt: heroSection?.heroExcerpt,
                background_video: heroSection?.promo_video_url,
                showIframe: true,
                external_video: heroSection?.hero_video_url,
                slug: slug,
            }} />
            <div className="text-light">
                {isLoading ?
                    <center> <Spinner animation="border" role="status" className='mx-auto my-3'>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner></center>
                    :
                    <div>
                        <div className="row px-4 pt-5 me-0 text-light">
                            <div className="b-divider-10"></div>
                            <div className="col-xl-8 mx-auto">
                                <div className="row">
                                    <div className="col-xl-10 mx-auto">
                                        <h3 className="display-3 fw-lighter">{page?.subTitle}</h3>
                                        <h1 className="display-2 fw-bold mb-4">{page?.title}</h1>
                                        <p className="lead mb-5 lh-6">{page?.excerpt}</p>
                                        <div className="row">
                                            {
                                                page?.page_video_urls ?
                                                    page.page_video_urls.map((videoUrl, index) => (
                                                        <div className="col-lg-6" key={index}>
                                                            <div className="overflow-hidden">
                                                                <div className="embed-responsive embed-responsive-16by9 mb-5">
                                                                    <video
                                                                        id="video-player"
                                                                        className="video border rounded"
                                                                        src={`${process.env.REACT_APP_VIDEO_URL}${videoUrl}`
                                                                        }
                                                                        autoPlay={true}
                                                                        muted={true}
                                                                        playsInline={true}
                                                                        loop={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : ''
                                            }
                                        </div>
                                        <div className="b-divider-6"></div>

                                        <h3 className="display-6 fw-lighter">{section?.sectionTitle}</h3>
                                        <h1 className="display-4 fw-bold mb-4">{section?.sectionSubTitle}</h1>
                                        <p className="lead mb-5 lh-6">{section?.sectionExcerpt}</p>
                                        <div className="embed-responsive embed-responsive-16by9"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="b-divider-6"></div>
                        </div>
                        <section className="py-5 text-dark">
                            <div className="b-divider-6"></div>
                            <div className="container col-xl-8">
                                <div className="row">
                                    <div className={`${colorConfig?.primaryColor} text-light rounded-5 col-lg-10 mx-auto px-5`}>
                                        <div className="b-divider-6"></div>
                                        <h3 className="display-6 fw-lighter">{featuredSection?.featureSubTitle}</h3>
                                        <h2 className="display-5 fw-bold mb-4">{featuredSection?.featureTitle}</h2>
                                        <p>{featuredSection?.featureExcerpt}</p>
                                        <div className="row g-4 py-5 row-cols-1 row-cols-lg-2">
                                            {
                                                featuredSection && featuredSection.featuredContent.length > 0 ?
                                                    featuredSection.featuredContent.map((content, index) => (
                                                        <div className="col d-flex align-items-start mb-5" key={index}>
                                                            <div className=" d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 mt-2">
                                                                <FaMagic />
                                                            </div>
                                                            <div>
                                                                <h3 className="fs-2">{content.title}</h3>
                                                                <p>{content.content}</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="b-divider-10"></div>
                        </section>
                        <div className='grid-wrapper'>
                            {workImages && workImages.length > 0 ?
                                workImages.map((image, index) => (
                                    <div key={index} className={` ${randoSizeImage.length > 0 ? _.sample(randoSizeImage) : ''} `}>
                                        <img style={{ padding: '3px' }} src={`${process.env.REACT_APP_IMAGE_URL_S3}images/${image}`} alt="" />
                                    </div>
                                )) : ''
                            }
                        </div>
                    </div>
                }
            </div>
            <SectionFooter />
        </div >
    )
}

export default WorkDetailPage
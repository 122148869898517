import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FaArrowCircleRight } from "react-icons/fa";
import { IoCloseCircleSharp, IoSend } from "react-icons/io5";
import { sendInquiryToMail } from '../../redux/slices/notificationsAPISlice';
import '../../assets/style/work-btn.css';
import '../../assets/style/scss/work.css';
import { colorConfig } from '../../constants/websiteConfigration.constants';

function ListCards({ data }) {
    const [userEmail, setUserEmail] = useState('');
    const [inquiryMessage, setInquiryMessage] = useState({});
    const dispatch = useDispatch();
    function playVideo(video_id) {
        document.getElementById(video_id).style.display = 'block';
    }

    function pauseVideo(video_id) {
        document.getElementById(video_id).style.display = 'none';
    }

    const handleShowInputField = (id) => {
        const fieldContainer = document.getElementById(`field-container-${id}`);
        const fieldBtn = document.getElementById(`field-btn-${id}`);
        if (fieldContainer.style.display === 'none') {
            fieldContainer.style.display = 'flex';
            fieldContainer.style.flexWrap = 'wrap'
            fieldContainer.style.alignContent = 'center'
            fieldBtn.style.display = 'none';
        } else {
            fieldBtn.style.display = 'block';
            fieldContainer.style.display = 'none';
        }

    }

    const handleSubmitInquiry = async (value) => {
        const sendInquiryData = {
            projectDetails: {
                id: value?._id,
                title: value?.list?.listTitle,
                categories: value?.category.toString(),
                url: `#detail/${value?.list?.slug}`
            },
            email: userEmail
        }
        try {
            const sendInquiry = await dispatch(sendInquiryToMail(sendInquiryData))
            
            if (sendInquiry?.payload?.data?.status === 200) {
                setInquiryMessage({ message: sendInquiry?.payload?.data?.message, inquiryId: value?._id, className: 'success' })
            }
            if (sendInquiry?.payload?.data?.error) {
                setInquiryMessage({ message: sendInquiry?.payload?.data?.message, inquiryId: value?._id, className: 'danger' })
            }
        } catch (error) {
            console.log("Error", error);
            setInquiryMessage({ message: error?.message, inquiryId: value?._id, className: 'danger' })
        }

    }
    return (
        <div className="container amazing-deals m-auto" id="custom-cards">
            <div className='row gy-5'>
                {
                    data.length > 0 ?
                        data.map((value, index) => (
                            <div className="col-lg-10  col-sm-10 mx-auto" key={index}>
                                <div className={`item ${colorConfig.primaryColor} text-light`}>
                                    <div className="row">
                                        <div className="col-lg-6" style={{ maxHeight: '25rem' }}>
                                            <div className="image">
                                                <div className="t-container" onMouseOver={() => playVideo(`video-${value._id}`)} onMouseOut={() => pauseVideo(`video-${value._id}`)} >

                                                    <img src={`${process.env.REACT_APP_IMAGE_URL_S3}images/${value.list.thumbnail_image_url}`} alt="Thumbnail" />

                                                    <div id={`video-${value._id}`} className="v-overlay">
                                                        <video autoPlay muted playsInline="playsInline" loop={true} >
                                                            <source src={`${process.env.REACT_APP_VIDEO_URL}${value.list.thumbnail_video_url}`} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content">
                                                <div className="d-flex justify-content-between">
                                                    <span className="techno">{value.list.technology}</span>
                                                </div>
                                                <h5 className="sub-title">{value.list.listSubTitle}</h5>
                                                <h4 className="fs-3">{value.list.listTitle}</h4>
                                                <p className="fs-7">{value.list.listExcerpt} </p>
                                                <div className="main-button d-flex gap-2">
                                                    <a href={`#detail/${value?.list?.slug}`} className="btn btn-light btn-list-explore">{value.list.buttonURL} <FaArrowCircleRight className='ms-2 fs-6 text-dark' style={{ marginTop: "-3px" }} /></a>
                                                    {inquiryMessage && inquiryMessage?.inquiryId === value._id ?
                                                        <span className={`text-${inquiryMessage?.className} m-0 p-1 d-flex align-items-center`}> {inquiryMessage?.message}</span>
                                                        :
                                                        <div className=''>
                                                            <a className={` btn btn-outline-secondary btn-list-inquiry`} id={`field-btn-${value._id}`} onClick={() => handleShowInputField(`${value._id}`)}>Send Inquiry</a>
                                                            <div className="input-group align-items-end bg-white border-1 border-dark border rounded-2" style={{ display: 'none' }} id={`field-container-${value._id}`}>
                                                                <button className="btn btn-danger inquiry-close" type="button" onClick={() => handleShowInputField(`${value._id}`)}>
                                                                    <IoCloseCircleSharp className='fs-5' />
                                                                </button>
                                                                <input type="email" onChange={(e) => setUserEmail(e.target.value)} className="form-control border-0" placeholder="Enter your e-mail" aria-label="Recipient's email" aria-describedby="basic-addon2" />
                                                                <div className="input-group-append">
                                                                    <button className="btn inquiry-send" onClick={() => handleSubmitInquiry(value)} type="button">
                                                                        <IoSend className='fs-5' /></button>

                                                                </div>
                                                            </div>
                                                            {/* </> */}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        <>
                            <h1 className=' text-center'>No data found...</h1>
                        </>
                }
            </div>
        </div>
    )
}

export default ListCards
import React, { useEffect, useState } from 'react'
import '../../assets/style/career.css'
import NavigationBar from '../../components/common/navigation-bar'
import SectionFooter from '../../components/common/section-footer'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCareerList } from '../../redux/slices/careerSlice'
import { Spinner } from 'react-bootstrap'
import Header from '../../components/common/headerSection'
import { CareerPageHeader } from '../../constants/header.constants'
import { colorConfig } from '../../constants/websiteConfigration.constants'

function Career() {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const navigator = useNavigate()
    const dispatch = useDispatch()

    const settingState = useSelector((state) => state.websiteSettings)
    const [careerData, setCareerData] = useState({});

    useEffect(() => {
        try {
            if (!settingState.loading) {
                setCareerData(settingState?.websiteSettings?.careerPage)
            }
        } catch (error) {
            console.log("Error", error);
        }
    }, [settingState])

    useEffect(() => {
        getCareerList()
    }, [])

    const getCareerList = async () => {
        setIsLoading(true)
        try {
            const resp = await dispatch(fetchCareerList())
            if (resp.payload?.data?.status === 200) {
                setData(resp.payload.data.data)
            } else if (resp.payload?.data?.error) {
                console.log("Error", resp.payload?.data?.message);
            }
        } catch (error) {
            console.log("Error", error);
        }
        setIsLoading(false)
    }

    return (
        <div className="career-page">
            <NavigationBar />
            <Header props={CareerPageHeader} />
            <div className={`bg-design-br ${colorConfig.secondaryColor} text-secondary px-4 py-5 text-center  text-light`}>
                <div className="py-5">
                    {careerData ?
                        <>
                            <h1 className="display-5 fw-bold">{careerData?.careerTitle}</h1>
                            <h2>{careerData?.careerSubTitle}</h2>
                            <div className="col-lg-7 py-3 mx-auto">
                                <p className="fs-5 mb-4">{careerData?.careerExcerpt}</p>
                            </div>
                        </>
                        : ''}
                </div>
            </div>

            <main className="bg-dark-blue">
                <div className="container px-4 py-5" id="custom-cards">
                    {isLoading ?
                        < Spinner animation="border" role="status" className='m-auto d-flex'>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> : ''
                    }
                    <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
                        {/* <!-- Unity Developer --> */}
                        {data ?
                            data.map((value, index) => (
                                <div className="col" key={index}>
                                    <div className={`card  ${value.bgColorClass ? value.bgColorClass : 'default-bg'} card-cover h-100 overflow-hidden text-bg-dark ${colorConfig?.primaryColor} rounded-4 shadow-lg`}
                                        //onClick={() => { navigator(`/career-detail/${value?._id}`) }}>
                                        onClick={() => { navigator(`/career-detail/${(value?.slug)}`) }}>
                                        <div className="arrow-icon"></div>
                                        <div className="background-image z-index-0 ">
                                            <img src={`${process.env.REACT_APP_IMAGE_URL}/_uploads/images/${value?.backgroundImage} `} alt="arrow" />
                                        </div>
                                        <div
                                            className="flex-column d-flex z-index-1 h-100 p-5 p-lg-4 p-xl-5 pb-lg-2 pb-xl-3 text-white text-shadow-1">
                                            <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">{value?.title}</h3>
                                            <ul className="d-flex list-unstyled mt-auto">
                                                <li className="me-auto">
                                                    <div className="aura-icon">
                                                        <img className='aura-icon' src={`${process.env.REACT_APP_IMAGE_URL}/_uploads/images/${value?.icon} `} alt="arrow" />
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center me-3">
                                                    <svg className="bi me-2" width="1em" height="1em">
                                                        {/* <use xlinkHref="#geo-fill"></use> */}
                                                    </svg>
                                                    <small>{value?.period}</small>
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <svg className="bi me-2" width="1em" height="1em">
                                                        {/* <use xlinkHref="#calendar3"></use> */}
                                                    </svg>
                                                    <small>{value?.workExperience}</small>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : <h1 className=' text-center justify-content-center d-flex m-auto'>No data found...</h1>}
                    </div>
                </div>
            </main >
            <SectionFooter />
            {/* <div w3-include-html="sections/section-footer.html"></div> */}
        </div >
    )
}

export default Career
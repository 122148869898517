// Header.js
import React from 'react';
import { Helmet } from 'react-helmet';

let logo = require('../../public/images/logo_aurainteract_white.png');
let url = 'https://www.aurainteract.com/';

const Header = ({ props }) => {
  return (
    <Helmet>
      <title>{props?.title}</title>
      <meta name="description" content={props?.description} />
      <meta property="og:title" content={props?.title} />
      <meta property="og:description" content={props?.description} />
      <meta property="og:image" content={props?.image ? props.image : logo} />
      <meta property="og:url" content={props?.url ? url + props?.url : url} />
      <meta property="og:type" content="article" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={props?.title} />
      <meta name="twitter:description" content={props?.description} />
      <meta name="twitter:image" content={props?.image ? props.image : logo} />
    </Helmet>
  );
};

export default Header;

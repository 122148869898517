let logo = require('../public/images/logo_aurainteract_white.png');

export let HomePageHeader = {
    title: 'Aura Interact Private Limited',
    description: 'Home Page',
    image: logo,
    url: '#',
}

export let ContactUsPageHeader = {
    title: 'Aurainteract - Contact Us', 
    description: "Contact Us",
    image: logo,
    url: `#contact`
}

export let CompanyPageHeader = {
    title: 'Aurainteract - Company',
    description: 'Company',
    image: logo,
    url: `#company`
}

export let TeamPageHeader = {
    title: 'Aurainteract - Team',
    description: 'Team',
    image: logo,
    url: `#team`
}

export let CareerPageHeader = {
    title: 'Aurainteract - Career',
    description: 'Career',
    image: logo,
    url: `#career`
}

export let WorkPageHeader = {
    title: 'Aurainteract - Work', 
    description: `Work`,
    image: logo,
    url: `#work`
}

export let BlogPageHeader = {
    title: 'Aurainteract - Blogs', 
    description: `Read Blogs`,
    image: logo,
    url: `#blog`
}
import { createAsyncThunk } from "@reduxjs/toolkit"
import { axiosClient } from "../../utils/axios-client"

export const userLogin = createAsyncThunk(
    'user-login',
    async (data) => {
        let args = {
            url: `/users/login`,
            method: 'post',
            data: data
        }
        return axiosClient(args)
    }
)

export const logoutAction = createAsyncThunk(
    'user-logout',
    async () => {
        localStorage.removeItem("access_token");
        return true;
    }

)

export const verifyJWTToken = createAsyncThunk(
    'verify-jwt-token',
    async (data) => {
        let args = {
            url: `/users/verify-token`,
            method: 'post',
            data: data
        }
        return axiosClient(args)
    }
)
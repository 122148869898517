import React from 'react'
import '../assets/style/page-not-found.css'
function PageNotFound() {
  return (
    <div class="fof-section">
      <h1 class="fof-error">404</h1>
      <div class="fof-page">Ooops!!! The page you are looking for is not found</div>
      <a class="fof-back-home" href="#">Back to home</a>
    </div>
  )
}

export default PageNotFound
import { createAsyncThunk, } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axios-client";

export const fetchModules = createAsyncThunk(
    'fetchmodule',
    async () => {
        let args = {
            url: "/modules-json/get-modules-list",
            method: "get",
        };
        return axiosClient(args)
    }
)
export const fetchModuleById = createAsyncThunk(
    'fetchmoduleById',
    async (slug) => {
        let args = {
            url: `/modules-json/get-modules-detail/${slug}`,
            method: 'get'
        }
        return axiosClient(args)
    }
)
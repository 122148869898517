import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchModules } from '../../redux/slices/modulesSlice'
import SectionHaveAnIdea from '../../components/section-have-an-idea'
import { Spinner } from 'react-bootstrap';
import { FaArrowCircleRight } from "react-icons/fa";
import SectionOurClients from '../../components/section-our-clients';
import { strings } from '../../constants/strings.constans';
import { colorConfig } from '../../constants/websiteConfigration.constants';


function SectionTrainingModule() {
	const dispatch = useDispatch()
	const [data, setData] = useState([])
	const [isLoading, setIsLoading] = useState(false)


	const getModuleList = async () => {
		setIsLoading(true)
		const resp = await dispatch(fetchModules())
		if (resp.payload?.data?.status === 200) {
			setData(resp.payload?.data?.data)
		}
		if (resp.payload?.data?.error) {
			console.log("Error", resp.payload.data.message);
		}
		setIsLoading(false)
	}
	
	useEffect(() => {
		getModuleList()
	}, [])
	return (

		<div className={`${colorConfig?.secondaryColor} section-service vr-training text-light`}>
			<div className="row px-4 pt-5 text-light">
				<div className="b-divider-10" ></div> {/* for spacing */}
				{isLoading &&
					<Spinner animation="border" role="status" className='mx-auto my-3'>
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				}
				{data && data.length > 0 ?
					data.map((item, index) => (
						<div className='trainings-section-container' key={index}>
							{index === strings.SHOW_CLIENTS_ID ?
								<div className='col-xl-8 mx-auto  clients-section'>
									<div className="b-divider-10" ></div>  {/* for spacing */}
									<SectionOurClients />
									<div className="b-divider-10" ></div>  {/* for spacing */}
								</div>
								: ''
							}
							<div className='col-xl-8 mx-auto ' >
								{/* //  <div className="b-divider-10" ></div> */}
								<div >
									<div className="row">
										<div className="col-xl-10 mx-auto">
											<span className="controller-image"></span>
											<h3 className="display-7 fw-lighter fst-italic text-white-75">{item.moduleSubTitle}</h3>
											{/* <h3 className="display-3 fw-lighter">{item.moduleSubTitle}</h3> */}
											<h1 className="display-2 fw-bold mb-4">{item.moduleTitle}</h1>
											<p className="lead">{item.ModuleExcerpt}</p>
											<p className="lead mb-5 lh-6"></p>
										</div>
									</div>

									{item.modules && item.modules.length > 0 ?
										item.modules.map((moduleItem, moduleIndex) => (
											<div className='' key={moduleIndex}>
												<div id="mdvrt" className="b-divider-10"></div>
												<div className="row">
													<div className="col-xl-10 mx-auto">
														<div className="row">
															{moduleItem?.promo_video_url ?
																moduleItem.promo_video_url.map((video_url, index) => (
																	<div className="col-lg-6" key={index}>
																		<div className="overflow-hidden">
																			<div className="embed-responsive embed-responsive-16by9 mb-5">
																				<video autoPlay muted playsInline="playsInline" loop={true} className='object-fit-contain border rounded body-video'>
																					<source src={`${process.env.REACT_APP_VIDEO_URL}${video_url}`} type="video/mp4" />
																					Your browser does not support the video tag.
																				</video>
																			</div>
																		</div>
																	</div>
																))
																: ''}
														</div>

													</div>
													<div className="col-xl-10 mx-auto">
														<h3 className="display-6 fw-lighter">{moduleItem.subTitle}</h3>
														<h2 className={`display-3 fw-bold mb-4 ${moduleItem.buttonColorClassName}`}>{moduleItem.title}</h2>
														<p className="lead mb-5 lh-6">{moduleItem.excerpt}</p>
														<div className="d-grid gap-2 d-sm-flex mb-5">
															{moduleItem.redirectUrl ?
																<a className="btn btn-primary btn-lg px-4 me-sm-3" href={moduleItem.redirectUrl}>{moduleItem.buttonUrl}<FaArrowCircleRight className='ms-2 fs-6' /></a>
																:
																<>
																	<a className="btn btn-primary btn-lg px-4 me-sm-3" href={`#work/${moduleItem?.slug}`}>{moduleItem.buttonUrl}<FaArrowCircleRight className='ms-2 fs-6' /></a>
																</>
															}
														</div>
													</div>
												</div>
											</div>

										))
										: ""}
								</div>
							</div>
						</div>
					)) : ""}
				<div className="b-divider-10" ></div>
				<SectionHaveAnIdea />
				<div className="b-divider-10" ></div>
			</div>
		</div>
	)
}

export default SectionTrainingModule


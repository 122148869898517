import $ from 'jquery';
import { colorConfig } from '../../constants/websiteConfigration.constants';
function includeHTML() {
	var z, i, elmnt, file, xhttp;
	/* Loop through a collection of all HTML elements: */
	z = document.getElementsByTagName("*");
	for (i = 0; i < z.length; i++) {
		elmnt = z[i];
		/*search for elements with a certain atrribute:*/
		file = elmnt.getAttribute("w3-include-html");
		if (file) {
			/* Make an HTTP request using the attribute value as the file name: */
			xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function () {
				if (this.readyState === 4) {
					if (this.status === 200) {
						elmnt.innerHTML = this.responseText;
					}
					if (this.status === 404) {
						elmnt.innerHTML = "Page not found.";
					}
					/* Remove the attribute, and call this function once more: */
					elmnt.removeAttribute("w3-include-html");
					includeHTML();
				}
			}
			xhttp.open("GET", file, true);
			xhttp.send();
			/* Exit the function: */
			return;
		}
	}
}
includeHTML();



$(window).on("load", function () {

	// Portfolio Filter
	var $portfolio_container = $('.portfolio-container');
	$portfolio_container.isotope({
		filter: '*',
		animationOptions: {
			queue: true
		}
	});

	$('.portfolio-nav').on('click', 'li', function () {
		$('.portfolio-nav .current').removeClass('current');
		$(this).addClass('current');
		var filterValue = $(this).attr('data-filter');
		$portfolio_container.isotope({
			filter: filterValue,
			animationOptions: {
				queue: true
			}
		});
	});

	// Mouse follow circle
	var $svg_container = $('.work svg')
	var $drone = $('.drone');
	var droneCenter = {
		x: $drone.width() / 2,
		y: $drone.height() / 2
	};

	$svg_container.on('mousemove', function (event) {
		$drone.css('transform', `translate3d(${event.offsetX - droneCenter.x}px, ${event.offsetY - droneCenter.y}px, 0)`);
	});
});

$(window).on("scroll", function () {
	var navbar = $('.top-nav');
	var btn = $('#button');
	var workFilters = $('.work-filters');

	//on Scroll show button
	if ($(window).scrollTop() > 300) {
		btn.addClass('show');
	} else {
		btn.removeClass('show');
	}
	//--

	//show navbar
	if ($(window).scrollTop() > 900) {
		navbar.addClass(colorConfig?.primaryColor);
		// navbar.addClass('scrolled');
	} else {
		navbar.removeClass(colorConfig?.primaryColor);
		// navbar.removeClass('scrolled');
	}
	//--

	//Hide filters on scroll down and show when scrolling
	if ($(window).scrollTop() > 900) {
		workFilters.addClass('scrolled');
	} else {
		workFilters.removeClass('scrolled');
	}
});

export const scrollTop = (e) => {
	e.preventDefault();
	window.scrollTo(0, 0);
}
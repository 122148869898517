import React from 'react';
import { FaYoutube, FaEnvelope, FaFacebook, FaLinkedin } from "react-icons/fa";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { FaXTwitter } from "react-icons/fa6";
import { colorConfig, websiteConfig } from '../../constants/websiteConfigration.constants';


function SectionFooter() {
  return (
    <footer className={`text-lg-start ${colorConfig?.primaryColor} text-light`}>
      <section className="container d-flex justify-content-center justify-content-lg-between p-4 border-bottom border-secondary">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>
        <div>
          <a href="https://www.youtube.com/@aurainteract1901" target='_blank' rel="noreferrer" className="me-4 text-reset text-decoration-none">
            <FaYoutube />
          </a>
          <a href="https://www.linkedin.com/company/aurainteract/" target='_blank' rel="noreferrer" className="me-4 text-reset text-decoration-none">
            <FaLinkedin />
          </a>
          <a href="https://m.facebook.com/aurainteractcom/" target='_blank' rel="noreferrer" className="me-4 text-reset text-decoration-none">
            <FaFacebook />
          </a>
          <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FAuraInteract" target='_blank' rel="noreferrer" className="me-4 text-reset text-decoration-none">
            <FaXTwitter />
          </a>
        </div>
      </section>
      <section className="">
        <div className="container text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <img className="w-50" src={websiteConfig?.logoTagLine} alt="" />
              </h6>
              <p>
                AuraInteract is a pioneering force in the metaverse sector, leading the charge in transforming businesses through its advanced XR and AI solutions.
              </p>
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 text-decoration-none">
              <h6 className="text-uppercase fw-bold mb-4">
                About
              </h6>
              <p>
                <a href="#company" className="text-reset text-decoration-none">Company</a>
              </p>
              <p>
                <a href="#team" className="text-reset text-decoration-none">Team</a>
              </p>
              <p>
                <a href="#contact" className="text-reset text-decoration-none">Contact</a>
              </p>
              <p>
              </p>
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                Useful links
              </h6>
              <p>
                <a href="#work" className="text-reset text-decoration-none">Work</a>
              </p>
              <p>
                <a href="#career" className="text-reset text-decoration-none">Career</a>
              </p>
              {/* <p>
                <a href="#blog" className="text-reset text-decoration-none">Blog</a>
              </p> */}
              <p>
              </p>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p><HiBuildingOffice2 className="me-3" />
                <span className=' d-inline-grid'>
                  A-668 Moneyplant High Street,<br /> Gota, Ahmedabad,<br /> Gujarat 382481
                </span>
              </p>
              <p>
                <FaEnvelope className='me-3' />
                <a href="mailto:info@aurainteract.com" className="text-reset text-decoration-none">info@aurainteract.com</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © {new Date().getFullYear()} Copyright: &nbsp;
        <a className="text-reset fw-bold" href="https://aurainteract.com/">aurainteract.com</a>
      </div>
    </footer>
  )
}

export default SectionFooter

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchBlogCategories } from '../../redux/slices/blogsAPISlice';
import { useParams } from 'react-router-dom';

function BlogCategorySection({ onCategoriesLengthChange }) {
  const { category } = useParams();
  const dispatch = useDispatch();
  const [blogCategories, setBlogCategories] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const maxCategoriesToShow = 7;

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const response = await dispatch(fetchBlogCategories());
    if (response?.payload?.data?.status === 200) {
      const categories = response?.payload?.data?.data;
      setBlogCategories(categories);
      onCategoriesLengthChange(categories.length); // Send length to parent
    }
    if (response?.payload?.data?.error) {
      console.log("Error", response?.payload?.data);
    }
  };

  return (
    <div className='d-bock'>
      <div className='d-flex flex-wrap'>
        {category &&
          <a className="_he _jy _bk _bm" rel="noopener follow" href={`#blog`}>
            <div className="_ce _dq _fe _ie _kr _ks _kt _by _b _bz _ca _ku text-capitalize bg-dark text-white border border-dark">
              All
            </div>
          </a>
        }
        {blogCategories.slice(0, showAll ? blogCategories.length : maxCategoriesToShow).map((categories, index) => (
          <div className="_if _n" key={index}>
            <a className="_he _jy _bk _bm" rel="noopener follow" href={`#blog/${categories?.category}`}>
              <div className={`${category === categories?.category && 'border-1 border border-white'} _ce _dq _fe _ie _kr _ks _kt _by _b _bz _ca _ku text-capitalize bg-dark text-white border border-dark`}>
                {categories?.category}
              </div>
            </a>
          </div>
        ))}
      </div>
      {blogCategories?.length > maxCategoriesToShow && 
      <div className="_if _n" style={{ cursor: 'pointer' }}>
        <div className="_ik _ah" onClick={() => setShowAll(!showAll)}>
          <p className="_by _b _bz _ca _il cursor-pointer">
            {!showAll ? 'See more topics' : 'See less'}</p>
        </div>
      </div>
      }
    </div>
  );
}

export default BlogCategorySection;

import React, { useEffect, useState } from 'react'
import NavigationBar from '../../components/common/navigation-bar'
// import '../assets/style/scss/work.scss'
import '../../assets/style/scss/work.css'
import SectionFooter from '../../components/common/section-footer'
import { useDispatch } from 'react-redux'
import { fetchModuleById } from '../../redux/slices/modulesSlice'
import { useParams } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import SectionHaveAnIdea from '../../components/section-have-an-idea'
import ScrollTopBtn from '../../ui-components/ui-common/scrollTopBtn'
import HeroPage from '../../components/common/heroPage'
import { strings } from '../../constants/strings.constans'
import Header from '../../components/common/headerSection'
import { colorConfig } from '../../constants/websiteConfigration.constants'
import ListCards from '../../components/Work/list-cards'

function ModuleDetail() {
    const { slug } = useParams()
    const [data, setData] = useState([])
    const [heroVideo, setHeroVideo] = useState()
    const [modulePageData, setModulePageData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        getList()
    }, [])

    const getList = async () => {
        setIsLoading(true)
        try {
            const resp = await dispatch(fetchModuleById(slug,))
            if (resp.payload?.data?.status === 200) {
                setHeroVideo(resp.payload.data?.data[0]?.list?.thumbnail_video_url)
                setData(resp.payload.data.data)
                setModulePageData(resp.payload.data.pageData)
            } else if (resp.payload?.data?.error) {
                console.log("Error", resp.payload?.data?.message);
            }
        } catch (error) {
            console.log("Error", error);
        }
        setIsLoading(false)
    }
    return (
        <div className={`${colorConfig.secondaryColor}`}>
            <NavigationBar />
            <Header props={{title:modulePageData?.moduleHeroPage?.moduleTitle,
                            description:modulePageData?.moduleHeroPage?.moduleExcerpt,
                            }}/>
            <HeroPage props={{
                title: modulePageData?.moduleHeroPage?.moduleTitle, subTitle: modulePageData?.moduleHeroPage?.moduleSubTitle,
                excerpt: modulePageData?.moduleHeroPage?.moduleExcerpt, showIframe: true,
                external_video: modulePageData?.moduleHeroPage?.module_video_url,
                background_video: modulePageData?.moduleHeroPage?.promo_video_url
            }} />

            <ScrollTopBtn />
            <div className=' b-divider-10'></div>
            {modulePageData?._id === strings.SHOW_NVRT_ID ?
                <div className='container pt-5' >
                    <div className="col-xl-10 mx-auto">
                        <div className='row'>
                            <div className='col-md-5'>
                                <img src={require("../../assets/images/clients-images/nvrt-logo.b8b3708e.svg").default} alt="" className="nvrt-logo-side" />
                            </div>
                            {/* <div ></div> */}
                            <div className='col-md-5'>
                                <img src={require("../../assets/images/clients-images/cbet.png")} alt="" className="w-100" />
                            </div>
                        </div>
                        <div className="row text-white mt-5">
                            <div className="col-lg-12 mb-5">
                                <h3 className="display-6 fw-bold mb-4">In collaboration with NVRTLabs</h3>
                                <p className="big">Explore cutting-edge virtual reality training modules developed exclusively for healthcare professionals by NVRT Labs. Our immersive simulations, featuring advanced medical equipment, redefine the training experience. Elevate your skills and proficiency in healthcare maintenance through state-of-the-art virtual reality technology. Welcome to the future of medical training with NVRT Labs.</p>
                            </div>
                        </div>
                    </div>
                    <div className=' b-divider-10'></div>
                </div>
                : ''}

            <>
                {isLoading ?
                    <Spinner animation="border" role="status" className='m-auto d-flex'>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    <ListCards data={data} />
                }
            </>
            <div className=' py-5 bg-dark-blue'>
                <SectionHaveAnIdea bgColor={`${colorConfig.secondaryColor}`} />
            </div>
            <SectionFooter />
            <script src="https://code.jquery.com/jquery-3.7.1.js"></script>
            <script src="https://unpkg.com/@popperjs/core@2/dist/umd/popper.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossOrigin="anonymous"></script>
            <script src="js/isotope.pkgd.js"></script>
            <script src='js/main.js'></script>
        </div>
    )
}

export default ModuleDetail
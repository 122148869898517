import mainLogo from '../assets/images/main_logo.png'
import logotagLine from '../assets/images/logo_aurainteract_white_tagline.svg'
import logotagBlackLine from '../assets/images/logo_aurainteract_black_tagline.png'
import logoIcon from '../assets/images/aura-logo-icon.png'

export const websiteConfig = {
    mainLogo: mainLogo,
    logoTagLine: logotagLine,
    logoTagBlackLine: logotagBlackLine,
    logoIcon: logoIcon,
    companyTitle: "Empowering Immersive Industries",
    companySubTitle: "This is subtitle",
    companyExcerpt: "AuraInteract is a pioneering force in the metaverse sector, leading the charge in transforming businesses through its advanced XR and AI solutions. With a clear goal of expediting digital progress, the company offers holistic services covering the entire spectrum of XR and AI, fostering immersive environments and driving groundbreaking innovation. By seamlessly integrating XR and AI technologies, AuraInteract enables businesses to tap into new levels of productivity and engagement.\n\r\n At its heart, AuraInteract is driven by a deep understanding of the impact of XR and AI on human interactions and the physical world. Comprising a dynamic team of dedicated entrepreneurs, problem-solvers, and designers, the company is committed to leveraging these technologies for the greater good. Guided by the shared belief that technology can bring about positive global change, AuraInteract is dedicated to creating a future where everyday experiences are enriched and optimized through the seamless blending of XR and AI advancements, cultivating a world that is not only more interconnected but also more sustainable and efficient."
}

export const colorConfig = {
    primaryColor: 'bg-dark-blue-acent', //navbar,footer and category
    secondaryColor: 'bg-dark-blue' //background color of container
}
import { thunk } from 'redux-thunk';
import { configureStore } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import workSlice from "./slices/workSlice";
import careerSlice from './slices/careerSlice';
import teamSlice from './slices/teamSlice';
import websiteSettingsSlice from './slices/websiteSettingsSlice';
import userSlice from './atuhSlices/AuthSlice'
const reducer = combineReducers({
  user:userSlice,
  works: workSlice,
  career: careerSlice,
  team: teamSlice,
  websiteSettings:websiteSettingsSlice
})

const persistConfig = {
  key: 'root',
  storage
}


const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  applyMiddleware: thunk,
})
export default store;
import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axios-client";

export const fetchWebsiteSettings = createAsyncThunk(
    'fetchWebsiteSettings',
    async () => {
        let args = {
            url: "/website-settings-json/get-settings-list",
            method: "get",
        };
        return axiosClient(args)
    }
)

const websiteSettings = createSlice({
    name: "websiteSettings",
    initialState: {
        loading: false,
        websiteSettings: [],
        websiteSettingsError: null
    },
    extraReducers: (builder) => {
        builder

            //GET ALL Work
            .addCase(fetchWebsiteSettings.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchWebsiteSettings.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload?.data?.status === 200) {
                    state.websiteSettings = action.payload.data.data;
                    state.websiteSettingsError = null
                } else if (action.payload?.data?.error) {
                    state.websiteSettingsError = action.payload.data.message;
                    console.log("Error", action.payload.data);
                }
            })
            .addCase(fetchWebsiteSettings.rejected, (state, action) => {
                state.loading = false;
                state.websiteSettingsError = `Settings Error ${action.error ? action.error.message : "Unknown Error"}`;
            })
    }
})

export default websiteSettings.reducer;
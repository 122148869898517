//   <!-- BLOG-DETAIL PAGE NAVIGATIONBAR ANIMATION -->
let lastScrollTop = 0;
const isBlogNavbar = document.getElementById("top-navbar");

if (isBlogNavbar) {
    window.addEventListener("scroll", function () {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        // let scrollTop = window.scrollY || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop) {
            // Scrolling down
            document.getElementById("top-navbar").classList.add("hidden");
        } else {
            // Scrolling up
            document.getElementById("top-navbar").classList.remove("hidden");
        }

        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    }, false);
    // <!-- END/ -->
}
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import HeroPage from '../../components/common/heroPage';

function SectionHero() {
    const settingState = useSelector((state) => state.websiteSettings)
    const [homePageData, setHomePageData] = useState({});

    useEffect(() => {
        if (!settingState.loading) {
            setHomePageData(settingState?.websiteSettings?.homePage)
        }
    }, [settingState])

    return (
        <div className="video-background-holder">
            {homePageData ?
                <>
                    <HeroPage props={{
                        titleSm: homePageData?.homeTitle,
                        showLogo: true,
                        background_video: homePageData?.promo_video_url,
                        showIframe: true,
                        external_video: homePageData?.home_video_url
                    }} />
                </>
                : ''}
        </div>
    )
}

export default SectionHero
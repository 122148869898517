import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
// Send pageview with a custom path (take parameters [hitType, page, title,])
export const GAPageViews = (events) => {
    ReactGA.send(events);
}


// Google Analytics Event Play Video 
export const GAPlayEvent = (video) => {
    ReactGA.event('video_engagement', 'play', {
        video_id: video?.videoId,
        video_title: video?.videoTitle,
        event_category: 'Video',
        event_label: video?.video_path,
    });
};

// Google Analytics Event Pause Video
export const GAPauseEvent = (video) => {
    ReactGA.event('video_engagement', 'pause', {
        video_id: video?.videoId,
        video_title: video?.videoTitle,
        event_category: 'Video',
        event_label: video?.video_path,
    });
};

// Google Analytics Event Complete Video
export const GACompleteWatchEvent = (video) => {
    ReactGA.event('video_engagement', 'complete', {
        video_id: video?.videoId,
        video_title: video?.videoTitle,
        event_category: 'Video',
        event_label: video?.video_path,
    });
};

import React, { useEffect, useState } from 'react'
import SectionFooter from '../../components/common/section-footer'
import NavigationBar from '../../components/common/navigation-bar'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchListBySlug } from '../../redux/slices/careerSlice'
import { sendAutoReply, sendCareerMail } from '../../redux/slices/notificationsAPISlice'
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import Header from '../../components/common/headerSection'
import ReactGA from "react-ga4";
import { GAPageViews } from '../../google-analytics/google.analytics'
import { colorConfig } from '../../constants/websiteConfigration.constants'

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    resume: yup
        .mixed()
        .test('fileType', 'Unsupported file format, PNG, JPG, JPEG and PDF allowed', (value) => {
            if (!value) return true; // Empty value is allowed
            return (
                value && ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(value.type)
            );
        }).required('Resume is required'),
});

function CareerDetail() {
    var { slug } = useParams()
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    //const [isLoading, setIsLoading] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [disabled, setDisabled] = useState(false);
    const [alertInfo, setAlertInfo] = useState({
        display: false,
        message: '',
        type: '',
    });

    const toggleAlert = (message, type) => {
        try {
            setAlertInfo((prevState) => ({ ...prevState, display: true, message, type }));

            // Hide alert after 5 seconds
            setTimeout(() => {
                setAlertInfo((prevState) => ({ ...prevState, display: false, message: '', type: '' }));
            }, 5000);
        } catch (error) {
            console.log("Error", error);
        }
    };


    useEffect(() => {
        const hashPath = window.location;
        GAPageViews({ hitType: "pageview", page: hashPath.hash + hashPath.search || '/', title: hashPath.hash + hashPath.search || '/' });
        getData()
    }, [])
    const getData = async () => {
        //setIsLoading(true)
        const res = await dispatch(fetchListBySlug(slug))
        if (res?.payload?.data?.status === 200) {
            setData(res.payload.data.data)
        }
        if (res?.payload?.data?.status === 404) {
            console.log("Error", res.payload.data.message)
        }
        if (res?.payload?.data?.error === true) {
            console.log("Error", res.payload.data.message)
        }
        //setIsLoading(false)
    }
    const submitMail = async (values) => {
        // e.preventDefault()
        try {
            setDisabled(true);

            var formdata = new FormData();
            formdata.append("name", values.name);
            formdata.append("email", values.email);
            formdata.append("jobTitle", data?.title);
            formdata.append("attachments", values.resume);

            const autoReplyData = {
                name:values?.name,
                email:values?.email,
                jobTitle:data?.title
            }

            const resp = await dispatch(sendCareerMail(formdata))
            if (resp?.payload?.data[0]?.status === 200) {
                await dispatch(sendAutoReply(autoReplyData))
                setFormSubmited(true)
            }
            if (resp?.error) {
                console.log("Error", resp.error);
                toggleAlert('Oh. Something went wrong.', 'danger');
            }
        } catch (e) {
            console.error(e);
            toggleAlert('Oh. Something went wrong.', 'danger');
        } finally {
            setDisabled(false);
        }
    }
    return (
        <div>
            <NavigationBar />
            <Header props={{ title: data?.title, image: `${process.env.REACT_APP_IMAGE_URL}/_uploads/images/${data?.backgroundImage} `, description: data?.subTitle, url: `#career-detail/${slug}` }} />
            <div className={`bg-design-br ${colorConfig?.secondaryColor} text-light px-4 py-5 text-center`}>
                <div className="py-5">
                    <h1 className="display-5 fw-bold text-white py-2">{data?.title}</h1>
                    <div className="col-lg-7 py-3 mx-auto">
                        <p className="fs-5 mb-4 ">{data?.subTitle}</p>
                    </div>
                </div>
            </div>

            <main className={`${colorConfig?.secondaryColor}`}>
                <div className="container text-light px-4 pb-5" id="custom-cards">
                    <div className="row flex-lg-row-reverse align-items-center g-5 py-5 fw-lighter">
                        <div className="col-sm-12 col-lg-7 align-self-start">
                            <p>{data?.detailExcerpt}</p>

                            <div className="col-md-7 col-lg-8 mt-5">
                                {alertInfo?.display && (
                                    <div className=' text-center align-items-center'>
                                        <div className={`alert alert-${alertInfo?.type} alert-dismissible `} role='alert'>
                                            {alertInfo?.message}
                                            <button
                                                type='button'
                                                className='btn btn-close'
                                                data-bs-dismiss='alert'
                                                aria-label='Close'
                                                onClick={() => { setAlertInfo({ display: false, message: '', type: '' }) }}>
                                            </button>
                                        </div>
                                    </div >
                                )}
                                {!formSubmited ?
                                    <Formik
                                        initialValues={{
                                            name: '',
                                            resume: '',
                                            email: '',
                                        }}
                                        validationSchema={schema}
                                        onSubmit={(values, { resetForm, }) => {
                                            var file = document.getElementById("file");
                                            submitMail(values)
                                            file.value = null
                                            resetForm();
                                        }}
                                    >
                                        {({ errors, touched, setFieldValue, }) => (
                                            <Form className=''>
                                                <div className='position-relative mb-3'>
                                                    <label htmlFor='resume'>Resume</label>
                                                    <input
                                                        className='form-control'
                                                        accept='image/png, image/jpeg, application/pdf'
                                                        id="file"
                                                        name="resume"
                                                        type="file"
                                                        onChange={(event) => {
                                                            const files = event.target.files[0];
                                                            setFieldValue("resume", files);
                                                        }}
                                                    />
                                                    {errors.resume && touched.resume ? (
                                                        <p className='text-danger'>{errors.resume}</p>
                                                    ) : null}
                                                </div>

                                                <div className=' mb-3 row'>
                                                    <div className='position-relative col-md-6'>
                                                        <label htmlFor='name'>Name</label>
                                                        <Field name="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter name" />
                                                        {errors.name && touched.name ? (
                                                            <p className='text-danger'>{errors.name}</p>
                                                        ) : null}
                                                    </div>
                                                    <div className='position-relative col-md-6'>
                                                        <label htmlFor='email'>Email</label>
                                                        <Field name="email" type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                                                        {errors.email && touched.email ? <p className='text-danger'>{errors.email}</p> : null}
                                                    </div>
                                                </div>
                                                <button type="submit" disabled={disabled} className='btn btn-primary w-100'>Apply Now</button>
                                            </Form>
                                        )}
                                    </Formik>
                                    :
                                    <h3 className='col-10 d-flex m-auto'>
                                        Your application has been submitted, thank you for applying. We will get back to you shortly.
                                    </h3>}
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-5 align-self-start">
                            <h6>Responsibilities:</h6>
                            <ul style={{ listStyleType: "disc", paddingLeft: '2rem' }}>
                                {
                                    data && data?.responsibilities?.length > 0 ?
                                        data.responsibilities.map((value, index) => (
                                            <li key={index}>{value}</li>
                                        )) : ''
                                }
                            </ul>
                            <h6>Experience:</h6>
                            <ul style={{ listStyleType: "disc", paddingLeft: '2rem' }}>
                                {
                                    data && data?.experience?.length > 0 ?
                                        data.experience.map((value, index) => (
                                            <li key={index}>{value}</li>
                                        )) : ''
                                }
                            </ul>

                            <h6>Employment Terms:</h6>
                            <ul style={{ listStyleType: "disc", paddingLeft: '2rem' }}>
                                {
                                    data && data?.employmentTerms?.length > 0 ?
                                        data.employmentTerms.map((value, index) => (
                                            <li key={index}>{value}</li>
                                        )) : ''
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
            <SectionFooter />
        </div>
    )
}

export default CareerDetail
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/common/headerSection';
import NavigationBar from '../../components/common/navigation-bar';
import SectionFooter from '../../components/common/section-footer';
import { ContactUsPageHeader } from '../../constants/header.constants';
import { sendContactMail } from '../../redux/slices/notificationsAPISlice';
import { colorConfig, websiteConfig } from '../../constants/websiteConfigration.constants';
import "../../assets/style/contact.css";

const Contact = () => {

    const settingState = useSelector((state) => state.websiteSettings)
    //const [companyData, setCompanyData] = useState({});
    const dispatch = useDispatch()
    useEffect(() => {
        try {
            if (!settingState.loading) {
                //setCompanyData(settingState?.websiteSettings)
            }
        } catch (error) {
            console.log("Error", error);
        }
    }, [settingState])

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();
    const [alertInfo, setAlertInfo] = useState({
        display: false,
        message: '',
        type: '',
    });

    const toggleAlert = (message, type) => {
        setAlertInfo({ display: true, message, type });
        // Hide alert after 5 seconds
        setTimeout(() => {
            setAlertInfo({ display: false, message: '', type: '' });
        }, 5000);
    };

    const onSubmit = async (data) => {
        try {
            const resp = await dispatch(sendContactMail(data))
            if (resp?.payload?.data?.status === 200) {
                toggleAlert('E-mail submission was successful!', 'success');
            }
            if (resp?.error) {
                console.log("Error ", resp?.error);
                toggleAlert('Uh oh. Something went wrong.', 'danger');
            }
        } catch (e) {
            console.error(e);
            toggleAlert('Uh oh. Something went wrong.', 'danger');
        } finally {
            reset();
        }
    };

    return (
        <div className={`${colorConfig?.secondaryColor} text-light`}>
            <NavigationBar />
            <Header props={ContactUsPageHeader} />
            <div className={`container py-4 ${colorConfig?.secondaryColor}`} style={{ margin: "10rem auto" }}>
                <div className='row justify-content-center'>
                    <div className='d-flex' id="contact-container">
                        <div className='col-lg-6 row no-gutters flex-column m-auto align-items-center'>
                            <img src={websiteConfig?.logoTagLine} className="w-75 my-3 pb-5" alt='' />
                            <img src={require("../../assets/images/upwork-badge.png")} className="w-75 my-3 pb-5" alt='' />
                        </div>
                        <div className='col-lg-6'>
                            <div className='wrapper'>
                                <div className='row no-gutters'>
                                    <div className="col d-flex align-items-stretch">
                                        <div className="contact-wrap w-100 p-md-5 p-4 py-5">
                                            <h3 style={{ padding: "0 1rem" }}>Contact us</h3>
                                            <p className="mb-4 fs-5" style={{ padding: "0 1rem" }}>Welcome to Aura Interact! We appreciate your interest and look forward to assisting you. Please feel free to reach out to us using the information below.</p>
                                            {alertInfo?.display && (
                                                <div className=' text-center align-items-center'>
                                                    <div className={`alert alert-${alertInfo?.type} alert-dismissible `} role='alert'>
                                                        {alertInfo?.message}
                                                        <button
                                                            type='button'
                                                            className='btn btn-close'
                                                            data-bs-dismiss='alert'
                                                            aria-label='Close'
                                                            onClick={() => { setAlertInfo({ display: false, message: '', type: '' }) }}>
                                                        </button>
                                                    </div>
                                                </div >
                                            )}
                                            <form id='contact-form' className="contactForm" onSubmit={handleSubmit(onSubmit)} noValidate>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <input
                                                                id="name"
                                                                type='text'
                                                                className='form-control'
                                                                name='name'
                                                                placeholder='Name'
                                                                {...register('name', {
                                                                    required: { value: true, message: 'Please enter your name' },
                                                                    maxLength: {
                                                                        value: 30,
                                                                        message: 'Please use 30 characters or less'
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                        {errors.name && <span className='errorMessage text-danger'>{errors.name.message}</span>}
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <input
                                                                id="email"
                                                                type='email'
                                                                name='email'
                                                                className='form-control'
                                                                placeholder='Email address'
                                                                {...register('email', {
                                                                    required: true,
                                                                    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                                                })}
                                                            />
                                                        </div>
                                                        {errors.email && (
                                                            <span className='errorMessage text-danger'>Please enter a valid email address</span>
                                                        )}
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <textarea
                                                                id="message"
                                                                name='message'
                                                                rows={8}
                                                                className='form-control'
                                                                placeholder='Message'
                                                                {...register('message', {
                                                                    required: true
                                                                })}
                                                            />
                                                        </div>
                                                        {errors.message && <span className='errorMessage text-danger'>Please enter a message</span>}
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <button
                                                                className='btn btn-primary'
                                                                type='submit'>Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SectionFooter />
        </div>
    );
};

export default Contact;
import React from 'react'

function SectionHaveAnIdea(props) {
    return (
        <div className={`${props?.bgColor} text-secondary px-4`}>
            <div className="container col-xxl-8 px-4">
                <div className="row flex-lg-row-reverse align-items-center g-5 ">
                    <div className="col-10 col-sm-8 col-lg-6">
                        <img src={require("../assets/images/20006782_ALT01.png")} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
                    </div>
                    <div className="col-lg-6">
                        <h1 className="display-5 noto-font fw-thin lh-1 mb-3 text-white">Have an <strong><i>innovative</i></strong> idea?</h1>
                        <p className="lead">We're here to bring it to life. Share your vision with us, and let's collaborate to turn your concept into reality.</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <a href='#contact' className="btn btn-primary btn-lg px-4 me-md-2">Let's Talk </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SectionHaveAnIdea


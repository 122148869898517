import React, { useEffect, useState } from 'react'
import { fetchBlogCategories, fetchFeaturedBlogs } from '../../redux/slices/blogsAPISlice'
import { useDispatch } from 'react-redux'
import { IoMdTrendingUp } from "react-icons/io";
import { calculateReadingTime } from './blog-card-section'

function FeaturedBlogSection({ maxToShow }) {
    const disptach = useDispatch()
    const [featuredBlogs, setFeaturedBlogs] = useState([])
    const [showAll, setShowAll] = useState(false)
    useEffect(() => {
        getFeaturedBlogs()
    }, [])

    const getFeaturedBlogs = async () => {
        const response = await disptach(fetchFeaturedBlogs())
        if (response?.payload?.data?.status === 200) {
            setFeaturedBlogs(response?.payload?.data?.data)
        }
        if (response?.payload?.data?.error) {
            // console.log("Error", response?.payload?.data)
        }
    }
    return (
        <div  className='blog-featured-section' >{
            featuredBlogs?.length > 0 &&
            <div className='d-block' style={{ paddingTop: '16px' }}>
                <div className="_js _n _o _bc mb-4">
                    <IoMdTrendingUp style={{ width: '28', height: '29', viewBox: "0 0 28 29", color: 'white' }} className="_jt _ah" />
                    <h2 className="_by _fy _et _ca _id _cb">Trending</h2>
                </div>
                <div className='d-flex flex-wrap'>
                    {featuredBlogs.slice(0, showAll ? featuredBlogs.length : maxToShow).map((item, index) => (
                        <div className="_gd _ge _gf _gh _gi _gj _gk _gl _gm _gn _gp _gq _gt _gu  _gx" key={index}>
                            <div className="_al _cw">
                                <div className="_pw-trending-post _gy _cw _al _n _fh">
                                    {/* <div className="_gz _ju _ah _ii _dq _ha"> */}
                                        {/* <span className="_by _fy _fz _ga _gb _gc">{index + 1}</span> */}
                                    {/* </div> */}
                                    <div className="_n _cx">
                                        <div className="_iu _ah">
                                            <div className="_n _o _bc"><a tabIndex="0" rel="noopener follow"
                                                href={`#read-blog/${item?.slug}`}>
                                                <div className="_ah _dq">
                                                    <img alt="profile pic" className="_ah _cs _jw _hf _hg _fe" src={`${process.env.REACT_APP_IMAGE_URL_S3}${item?.author?.profile_pic}`} width="20" height="20" loading="lazy" />
                                                    <div className="_jv _jw _ah _hf _hg _jx _ao _jy _jz"></div>
                                                </div>
                                            </a>
                                                <div className="_ka _n _o _fi">
                                                    <div className="_n _o"><a
                                                        className="_bd _be _bf _bg _bh _bi _bj _bk _bl _bm _bn _bo _bp _bq _br"
                                                        rel="noopener follow"
                                                        href={`#read-blog/${item?.slug}`}>
                                                        <h4
                                                            className="_by _fy _jj _kb _dp _kc _kd _ke _kf _kg _kh _ki _cb _kj">
                                                            {item?.author?.name}</h4>
                                                    </a>
                                                        <div className="_kk _ah">
                                                            <div className="_n _kl">
                                                                <div className="_n"><svg width="16" height="16"
                                                                    viewBox="0 0 16 16" fill="none">
                                                                    <path
                                                                        d="M15.16 8c0 .65-.46 1.14-.86 1.57-.23.25-.47.5-.56.72-.1.22-.09.55-.1.88 0 .6-.01 1.3-.48 1.78-.48.48-1.16.5-1.75.5-.32 0-.65.01-.86.1-.2.07-.46.33-.7.57-.42.41-.9.88-1.54.88s-1.12-.47-1.54-.88a2.87 2.87 0 0 0-.7-.58c-.22-.09-.54-.08-.87-.09-.59 0-1.27-.02-1.74-.5s-.48-1.17-.49-1.78c0-.33-.01-.67-.1-.88-.07-.2-.32-.47-.55-.71-.4-.44-.87-.93-.87-1.58s.46-1.14.87-1.58c.23-.24.47-.5.56-.71.09-.22.08-.55.09-.88 0-.6.02-1.3.49-1.78s1.15-.5 1.74-.5c.33 0 .66-.01.86-.1.2-.08.47-.33.7-.57.43-.41.91-.88 1.55-.88.63 0 1.12.47 1.54.88.24.24.49.48.7.58.22.09.54.08.86.09.6 0 1.27.02 1.75.5.47.48.48 1.17.49 1.78 0 .33 0 .67.09.88.08.2.33.47.56.71.4.44.86.93.86 1.58z"
                                                                        fill="#437AFF"></path>
                                                                    <path
                                                                        d="M7.33 10.5c.2 0 .38.08.52.22.13.14.21.33.21.53 0 .07.03.13.07.18a.24.24 0 0 0 .35 0 .25.25 0 0 0 .07-.18c0-.2.08-.39.22-.53a.73.73 0 0 1 .52-.22h1.96c.13 0 .25-.05.34-.15a.5.5 0 0 0 .15-.35V6a.5.5 0 0 0-.15-.35.48.48 0 0 0-.34-.15H9.78c-.33 0-.64.13-.87.37-.23.23-.36.55-.36.88v2.5c0 .07-.02.13-.07.18a.24.24 0 0 1-.35 0 .25.25 0 0 1-.07-.18v-2.5c0-.33-.13-.65-.36-.88a1.21 1.21 0 0 0-.86-.37H5.37a.48.48 0 0 0-.35.15.5.5 0 0 0-.14.35v4c0 .13.05.26.14.35.1.1.22.15.35.15h1.96z"
                                                                        fill="#fff"></path>
                                                                </svg></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="_iu _ah">
                                            <a className="_bd _be _bf _bg _bh _bi _bj _bk _bl _bm _bn _bo _bp _bq _br"
                                                href={`#read-blog/${item?.slug}`}
                                                rel="noopener follow">
                                                <div className="">                                                    <h2 className="_by _km _et _ca _dp _kd _ke _ko _kg _ki _id _cb elleps-text">
                                                    <div className='elleps-text'>{item?.title}</div></h2>
                                                </div>
                                            </a>
                                        </div>
                                        <span className="_by _b _jj _ca _iw"><div className="_n _o">
                                            <span>
                                                {new Date(item?.uploadedDate).toLocaleString('default', { month: 'long' })} &nbsp;
                                                {new Date(item?.uploadedDate).getDate()},&nbsp;
                                                {new Date(item?.uploadedDate).getFullYear()}
                                            </span>
                                            <div className="_kp _kq _ah" aria-hidden="true">
                                                <span className="_ah" aria-hidden="true">
                                                    <div className="_kn _ko _cw _ah _dq _mm" aria-hidden="true"><span className="_ah" aria-hidden="true"><span className="_by _b _bz _ca _iw">·</span></span></div>
                                                </span>
                                            </div>
                                            <span className="_pw-reading-time _by _b _jj _ca _iw">{item?.content ? `${calculateReadingTime(item?.content)}min read` : ''}</span>
                                        </div></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                        //
                    }
                </div>
                {/* {fetchFeaturedBlogs && fetchFeaturedBlogs.length > maxToShow
                    && */}
                    {/* <div className="_if _n" style={{ cursor: 'pointer' }}>
                        <div className="_ik _ah" onClick={() => setShowAll(!showAll)}>
                            <p className="_by _b _bz _ca _il cursor-pointer">
                                {!showAll ? 'See more' : 'See less'}</p>
                        </div>
                    </div> */}
                {/* } */}
            </div>
        }
        </div>
    )
}

export default FeaturedBlogSection
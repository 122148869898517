import React, { useEffect, useState } from 'react'
import NavigationBar from '../../components/common/navigation-bar'
import '../../assets/style/scss/work.css'
import SectionFooter from '../../components/common/section-footer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWorkByCategory, fetchWorks } from '../../redux/slices/workSlice'
import { Spinner } from 'react-bootstrap'
import SectionHaveAnIdea from '../../components/section-have-an-idea'
import '../../assets/style/work-btn.css'
import ScrollTopBtn from '../../ui-components/ui-common/scrollTopBtn'
import HeroPage from '../../components/common/heroPage'
import Header from '../../components/common/headerSection'
import { WorkPageHeader } from '../../constants/header.constants'
import { colorConfig } from '../../constants/websiteConfigration.constants'
import ListCards from '../../components/Work/list-cards'

const categoryOptions = [
    "AR",
    "VR",
    "architecture",
    "education",
    "game",
    "tourism",
    "training"
]

function Work() {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const settingState = useSelector((state) => state.websiteSettings)
    const [workPageData, setWorkPageData] = useState({});
    const [selectedCategories, setSelectedCategories] = useState([]); // Initial state with 'all' selected


    useEffect(() => {
        try {
            if (!settingState.loading) {
                setWorkPageData(settingState?.websiteSettings?.workPage)
            }
        } catch (error) {
            console.log("Error", error);
        }
    }, [settingState])

    useEffect(() => {
        getList()
    }, [selectedCategories])

    const toggleCategory = (category) => {
        const index = selectedCategories.indexOf(category);
        if (index === -1) {
            setSelectedCategories([...selectedCategories, category]); // Add category if not selected
        } else {
            const newCategories = [...selectedCategories];
            newCategories.splice(index, 1); // Remove category if already selected
            setSelectedCategories(newCategories);
        }
        window.scrollTo(0, 880);
    };

    // Function to check if a category is selected
    const isCategorySelected = (category) => {
        return selectedCategories.includes(category);
    };


    // Fetch work data based on selected categories
    const getList = async () => {
        setIsLoading(true);
        try {
            if (selectedCategories.length > 0) {
                const toLower = selectedCategories.toString().toLowerCase() // convert array into lower string
                let selectedcategory = toLower.split(','); // covnert  string into array of strings
                const response = await dispatch(fetchWorkByCategory(selectedcategory));
                if (response.payload?.data?.status === 200) {
                    setData(response.payload.data.data);
                } else if (response.payload?.data?.error) {
                    setData([]);
                }
            } else {
                const response = await dispatch(fetchWorks())
                if (response.payload?.data?.status === 200) {
                    setData(response.payload.data.data);
                } else if (response.payload?.data?.error) {
                    setData([]);
                }
            }
        } catch (error) {
            console.log("Error", error);
        }
        setIsLoading(false);
    };


    return (
        <div>
            <NavigationBar />
            <Header props={WorkPageHeader} />
            <ScrollTopBtn />

            <HeroPage props={{
                title: workPageData?.workTitle, subTitle: workPageData?.workSubTitle,
                excerpt: workPageData?.workExcerpt, showIframe: true,
                external_video: workPageData?.work_video_url,
                background_video: workPageData?.promo_video_url
            }} />
            <div className={`${colorConfig.secondaryColor} pt-5`}>
                <div className='container'>
                    <div className='row'>
                        <div className='m-5 work-filters  col-lg-10 justify-content-md-center justify-content-lg-start'>
                            {categoryOptions.map((item, index) => (
                                <button
                                    key={index}
                                    className={`btn text-white ${colorConfig?.primaryColor} m-1 ${isCategorySelected(item) ? 'active bg-white text-white' : ''}`}
                                    onClick={() => toggleCategory(item)}
                                >
                                    <span className=' text-capitalize'>{item}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                {isLoading ?
                    <Spinner animation="border" role="status" className='m-auto d-flex'>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> :
                    <ListCards data={data} />
                }
            </div>
            <div className=' py-5 bg-dark-blue'>
                <SectionHaveAnIdea bgColor={`${colorConfig.secondaryColor}`} />
            </div>
            <SectionFooter />
            <script src="https://code.jquery.com/jquery-3.7.1.js"></script>
            <script src="https://unpkg.com/@popperjs/core@2/dist/umd/popper.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossOrigin="anonymous"></script>
            <script src="js/isotope.pkgd.js"></script>
            <script src='js/main.js'></script>
        </div>
    )
}

export default Work

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../utils/axios-client";

export const fetchTeamList = createAsyncThunk(
    'fetchTeamList',
    async () => {
        let args = {
             url: "/team-json/get-team-list",
            // url: "/team/show-teammember",
            method: "get",
        };
        return axiosClient(args);
    }
);

const teamSlice = createSlice({
    name: "team",
    initialState: {
        loading: false,
        team: [],
        teamError: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTeamList.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTeamList.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload?.data?.status === 200) {
                    state.team = action.payload.data.data;
                    state.teamError = null;
                } else if (action.payload?.data?.error) {
                    state.teamError = action.payload.data.message;
                    console.log("Error", action.payload.data);
                }
            })
            .addCase(fetchTeamList.rejected, (state, action) => {
                state.loading = false;
                console.log("Error", action.error);
                state.teamError = `Fetching team Error ${action.error ? action.error.message : "Unknown Error"}`;
            });
    },
});

export default teamSlice.reducer;

import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

window.onload = function () {
    var grid = document.querySelector('.grid');
    if (typeof (grid) !== 'undefined' && grid !== null) {
        var masonry = new Masonry(grid, {
            itemSelector: '.grid-item',
            percentPosition: true,
            columnWidth: '.grid-sizer'
        });
        // Layout Masonry after each image loads
        imagesLoaded(grid).on('progress', function () {
            layoutMasonry();
        });
    }

    // Function to layout Masonry after each image loads
    function layoutMasonry() {
        masonry.layout();
    }


}
